.comment-thread-popover {
  display: none;
  background: white;
  position: absolute;
  margin-top: 4px;
  min-width: 350px;
  z-index: 1;
}

.comment-list {
  min-height: 300px;
  max-height: 50vh;
  overflow-y: auto;
  width: 268px;
}

.comment-row {
  display: flex;
  margin: 4px 16px;
}

.comment-author-name {
  display: block;
  font-weight: bold;
  margin-top: 4px;
}

.comment-text {
  max-width: 350px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
}

.comment-author-photo {
  font-size: 24px;
  margin-right: 4px;
}

.comment-creation-time {
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
}

.comment-input-wrapper {
  border-top: 1px solid #ced4da;
  display: flex;
  padding: 4px;
}

.comment-input {
  border: 0;
  margin-right: 1%;
}

.comment-input:focus {
  box-shadow: none;
}

.comment-thread-popover-header {
  display: flex;
  justify-content: flex-end;
  min-height: 20px;
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.comment-button {
  background: #48336e;
  border: 2px solid #48336e;
  color: #fff;
  border-radius: 5px;
  height: 33px;
  margin: auto;
  min-width: 50px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
}

.commentFont {
  font-size: 18px !important;
}

.resolveButton {
  background: #48336e;
  border: 2px solid #48336e;
  color: #fff;
  border-radius: 5px;
  height: 33px;
  min-width: 70px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
}

.resolveButton:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

.prevnextComment {
  color: #48336e;
}

.comment-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.comment-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.comment-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.comment-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.30rem;
}

.comments-inputBox {
  padding: 10px;
  border: none;
}

.comments-inputBox:focus {
  border: none !important;
}