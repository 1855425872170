.box input:checked ~ .Variable-Checkbox {
  background-color: #48336e;
}

.margin-add-btn {
  margin-left: -8px !important;
}

.md-radio {
  display: flex;
}

.editorcontainer {
  width: 100%;
  display: flex;
}

.varconfig {
  width: 20%;
  border: 1px solid black;
}

.editorconfig {
  width: 80%;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 0;
}

.md-radio.md-theme-default.md-checked .md-radio-container {
  margin: 12px !important;
}

.tabs-container {
  display: inline-block;
  width: 100%;
  height: 800px;
  overflow-y: auto;
  background: white;
}

.main-div {
  text-align: center;
}

.box {
  background: white;
  display: flex;
  margin: 5px;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #f0e9ff;
  padding: 0px 5px;
}

h6 {
  float: left;
  width: 100%;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

mark {
  padding: 0em !important;
}

.editor-add {
  text-align: left;
  width: 100%;
  color: #48336e;
  border-radius: 5px;
  height: 35px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  padding: 3px;
}

.closeBtnEditor {
  background: #e92020;
  height: 20px;
  margin: auto 3px;
  color: white;
  width: 20px;
  border-radius: 3px;
  font-size: 10px;
}

.variablecloseBtnEditor {
  background: #e92020;
  height: 20px;
  margin: auto 3px;
  color: white;
  width: 25px;
  border-radius: 3px;
  font-size: 10px;
}
.variableEditBtnEditor {
  background: #0c9c1a;
  height: 20px;
  margin: auto 3px;
  color: white;
  width: 25px;
  padding-left: 2px;
  border-radius: 3px;
  font-size: 10px;
}

.searchCloseBtnEditor {
  height: 20px;
  margin: 35px 5px 0px 0px;
  color: black;
  border: 2px solid #48336e;
  width: 20px;
  border-radius: 5px;
  font-size: 10px;
}

.searchCloseBtnEditor:hover {
  background: #48336e;
  color: #fff;
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.md-button-content {
  position: relative;
  z-index: 2;
  background: #48336e !important;
  font-weight: 700;
}

.Textbox-editor {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 90%;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 0 10px 0;
  font-family: "Roboto";
}

.Textbox-editor:focus {
  border: 2px solid #48336e;
}

.submitbtneditor {
  background: #48336e;
  border: 1px solid #48336e;
  color: #fff;
  border-radius: 4px;
  height: 35px;
  width: 100px;
  margin-top: 24px;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  position: relative;
  bottom: 12px;
}

.submitbtneditor:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.editorLabelButton {
  color: #48336e !important;
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
}

.SignBlockBtn {
  color: #48336e;
  border-radius: 5px;
  height: 35px;
  min-width: 100px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  padding: 3px;
  text-align: left;
}

.signature-block-styling {
  height: 70px;
  display: inline-table;
  width: 200px;
  margin: 15px;
}

.sig-identifier-container {
  color: white;
  margin: 3px 3px 0px 3px;
}

.sig-heading-container {
  margin: 0px 3px 0px 3px;
}

.validation-type {
  top: 35px;
}

.is-mandatory {
  top: 35px;
}

.Button-div {
  margin: auto;
}

.Signcheckbox {
  margin-top: 20px;
  transform: scale(1.2);
  margin-left: 10%;
  accent-color: #48336e;
}
.signatureCheckBox {
  margin-top: 20px;
  transform: scale(1.2);
  margin-left: 5%;
  accent-color: #48336e;
}

.Signmodal {
  font-size: 20px;
  white-space: nowrap;
  margin-top: 10px;
  margin-left: 5%;
  font-weight: 500;
}

.Variable-Checkbox {
  height: 20px;
  margin: auto 13px;
  color: black;
  border: 1px solid #48336e;
  border-radius: 5px;
  font-size: 10px;
  width: 15px;
  accent-color: #48336e;
}

.bottom-div {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 90%;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}

.bottom-div:focus {
  border: 2px solid #48336e;
}

.sign-header {
  color: rgb(84, 98, 113);
  padding: 10px;
}

.div-align {
  position: relative;
  top: 10%;
}

.variables-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
}

.variables-label-div {
  margin: 10px;
}

.textbox-align {
  text-align: left;
}

.Isrequest-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  margin: 15px 0px;
  font-family: "Roboto";
}

.search-select {
  color: #546271;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
  white-space: nowrap;
  float: left;
  margin: 0px 5px 0px 0px;
}

.InputVariable {
  position: relative;
  right: 14%;
  left: 7%;
  top: 2px;
  accent-color: #48336e;
}

.inputLable {
  margin-left: 2%;
  position: relative;
  font-weight: 500;
  color: #546271;
  right: 14%;
  left: 8%;
  top: 1px;
}

.Sign-div {
  color: #546271;
  margin: 23px 0px 0px 10px;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
}

.variable-div {
  margin-top: 10%;
}

.sign-content {
  display: flex;
  width: 75%;
  margin: auto;
}
.signature-container {
  display: flex;
  width: 75%;
}

.invalid_regex {
  color: red;
}

.Component-root-11 {
  margin-right: 0px !important;
}

.Tabs-margin {
  margin-left: auto !important;
  margin-right: auto !important;
}

.party-header-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  display: block;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto";
  text-align: center;
  margin: 0;
}

.party-Promisers-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  display: block;
  font-size: 14px;
  font-family: "Roboto";
  text-align: center;
  margin: 5px;
  padding: 0;
}

.promisers-main-div {
  display: grid;
}

.promisers-add {
  border: 2px solid #f0e9ff;
  color: #48336e;
  border-radius: 5px;
  height: 35px;
  margin: 10px auto;
  width: 100px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
}

.party-sub-div {
  border-bottom: 2px solid #eeeeee;
}

.promisers-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.config-label {
  color: #546271;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
}

.label-variable {
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  color: #546271;
}

.tabForParties-div {
  text-align: center;
  height: 300px;
  margin: 10px;
}

.searchBar-div {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #d9dff2;
  margin: 0 0 10px;
}

.parties-checkbox {
  display: inline-block;
  transform: scale(1.5);
  margin: 15px 10px 10px 10px;
  accent-color: #48336e;
}

.TPsearchCloseBtnEditor {
  height: 20px;
  color: black;
  border: 2px solid #48336e;
  width: 20px;
  border-radius: 5px;
  font-size: 10px;
}

.TPsearchCloseBtnEditor:hover {
  background: #48336e;
  color: #fff;
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.add-Variable-btn {
  background: #48336e;
  border: 2px solid #48336e;
  color: #fff;
  border-radius: 5px;
  height: 35px;
  min-width: 100px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
}

.add-Variable-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
  background-color: #48336e;
}

.variableAddColumm {
  border: 1px solid #48336e;
  color: #fff;
  border-radius: 5px;
  height: 35px;
  min-width: 100px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  margin: 3% auto;
  background: #48336e;
}

.variableAddColumm:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
  background-color: #48336e;
}

.promisse-divider {
  border-bottom: 2px solid #eeeeee;
}
.parties-main-div {
  margin: 5px;
}

.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid #f0e9ff;
  padding: 10px;
  font-family: "Roboto";
  font-size: 12px;
  margin: 5px;
  color: #48336e;
  cursor: pointer;
  border-radius: 5px;
}
.tabforParties-required {
  display: grid;
  grid-template-columns: 30% 10%;
}
.tabforParties-checkbox {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 90%;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 10px 0;
  font-family: "Roboto";
}
.currency-popup-input{
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 10%;
  font-family: "Roboto";
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.currency-popup-div{
  margin: 5% 0 0 5%;
}