.notification-settings{
display: flex;
}
.EditornotificationCheckBox{
    margin: 24px 5px 5px 5px;
    accent-color: #48336E;
}
.formRow-label{
    display: flex;
    gap: 20px; 
}