

/* Green */

.success {
  border-color: #04aa6d;
  color: green;
}

.success:hover {
  background-color: #04aa6d;
  color: white;
}

/* Blue */
.info {
  border-color: #fcc201;
  color: #fcc201;
}

.info:hover {
  background: #fcc201;
  color: white;
}

/* Orange */
.warning {
  border-color: #ff9800;
  color: orange;
}

.warning:hover {
  background: #ff9800;
  color: white;
}

.danger {
  border-color: #f44336;
  color: red;
}

.danger:hover {
  background: #f44336;
  color: white;
}

.all-reviewers-label {
  color: #39c292;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #39c292;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.closed-reviewers-label {
  color: #86a52a;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #86a52a;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.open-reviewers-label {
  color: #F3A361;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #F3A361;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}

.status-btn-reviwers {
  height: 100px;
  width: 150px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding-right: 83px;
  position: relative;
  margin: 10px;
}
.status-btn-reviwers:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.commentLabel {
  color: #546271;
  font-family: "Roboto";
  font-size: 16px !important;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
  margin-top: auto;
  margin-bottom: auto;
}
.commentTable {
  padding-left: 60px !important;
}
.Declined-reviwers {
  background-color: #EEF1FF;
}
.approved-reviwers {
  background-color: #EEF1FF;
}
.submitted-reviwers {
  background-color: #EEF1FF;
}
.count-div{
  text-align: center;
  width: inherit;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}
