.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #48336e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #48336e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.companySettingsStatusCheckbox {
  display: flex;
  align-items: center;
  margin: 2% 0;
  padding: 10px;
}

.companySettingsLabel {
  font-weight: bold;
  color: #546271;
  font-size: 14px !important;
  font-family: "Roboto";
}

.companySettings_main_container {
  display: grid;
  grid-template-columns: 12% 12%;
  padding: 10px;
}

.companySettings_submit_btn {
  float: right;
  margin-right: 33px;
  font-size: 12px;
  background: #c5e5e3;
  height: 35px;
  width: 100px;
  border: 0px solid #c5e5e3;
  color: black;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
}
.companySettings_submit_btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51);
}
.company-settings-div-password {
  margin: 10px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  padding: 10px;
}
.comapany-settings-lable-input-div {
  display: flex;
}
.comapany-settings-lable-keys {
  width: 50%;
  display: flex;
  align-items: center;
}
.comapany-settings-lable-margin {
  margin-bottom: 5px;
}
