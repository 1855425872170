.add-to-contract-checkbox {
  display: inline-block !important;
  cursor: pointer !important;
  position: relative !important;
  width: 24px !important;
  height: 24px !important;
  outline: none !important;
}


.fileUpload {
  display: flex;
  margin: 5px;
}

.file-uploader {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  padding: 15px;
  margin: 20px 5px 5px 5px;
}

.commentTable {
  padding-left: 100px;
}

/* .cardbody-scroll {
  padding: 1.25rem;
} */


.print-preview {
  width: fit-content;
  margin: auto;
  display: flex;
}

.fileDisplay {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto';
  padding: 5px;
  color: #546271;
  display: flex;
}
.fileDisplay-name {

  margin: 5px;
}

.comment-table {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  padding: 15px;
  margin: 5px;
}
.file-upload-label{
  font-size: 12px;
  color: #546271;
}