.add-to-contract-checkbox {
  display: inline-block !important;
  cursor: pointer !important;
  position: relative !important;
  width: 24px !important;
  height: 24px !important;
  outline: none !important;
  transform: scale(0.6) !important;
}

.TextAreaStyle {
  border-radius: 5px;
  border: 1px solid #e9ebec;
  width: 99%;
  padding: 10px;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: #333;
}

.TextAreaStyle:focus {
  border: 2px solid #48336e;
  outline: none;
}

.pdfPop {
  display: flex;
  width: 100%;
}

.formdivlabel {
  margin-left: 17%;
  margin-bottom: 12px;
  position: relative;
  bottom: 1%;
}

.fileUpload {
  height: 100px;
  width: 100%;
  min-width: 295px;
}

.marginButton {
  font-size: 11px;
  background: #48336e;
  height: 25px;
  width: 100px;
  border: 0px solid #48336e;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  margin: 0px 20px 0px 0px;
  white-space: nowrap;
}

.marginButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.marginButton:disabled,
marginButton[disabled] {
  background-color: #48336e;
  color: #fff;
  box-shadow: none;
  opacity: 0.1;
}

.ExecuteButton {
  font-size: 12px;
  background: #48336e;
  height: 35px;
  width: 120px;
  border: 0 solid #48336e;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  margin: 0 20px 0 0;
  white-space: nowrap;
  font-family: "Roboto";
}

.ExecuteButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.commentLabel {
  font-weight: 500;
  white-space: nowrap;
  margin: 0px;
  padding: 5px;
  font-size: 14px !important;
}

#frame {
  background: white;
  zoom: 0.5;
}

#embed {
  background: white;
  zoom: 0.5;
}

.previewCSS {
  height: 297mm;
  width: 210mm;
  margin: 150px;
}

.pdfobject-container {
  height: 50rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}

.react-pdf__Page {
  margin-top: 0px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px px #ccc;
  border-radius: 3px;
  margin: -5px 0px 0px 0px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 0px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.sig_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 8px;
  border: 3px solid #e9ebec;
  border-radius: 6px;
  margin: 5px;
  padding: 15px 15px 15px 30px;
  grid-column-gap: 50px;
}

.signature-block-styling {
  height: 70px;
  display: inline-table;
  width: 200px;
  margin: 15px;
}

.sig-identifier-container {
  color: white;
  margin: 20px 3px 0px 3px;
}

.sig-heading-container {
  margin: 0px 3px 0px 3px;
}

.input-txt {
  border: none;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  left: 20px;
  top: 26px;
  width: 50%;
}

.sign-label {
  float: left;
  width: 80%;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto";
  white-space: nowrap;
  color: #546271;
  padding: 5px;
  margin: 0px;
}

.text-form {
  height: 30px;
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0px 0px 0px 10px;
  font-size: 13px;
}

.text-form:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

.text-form:focus {
  border: 2px solid #48336e;
}

.sign-contract {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-family: "Roboto";
  line-height: 16px;
  white-space: nowrap;
}

.commentTable {
  padding-left: 100px;
}

.file-uploader {
  margin: 5px;
  border: 3px solid #e9ebec;
  border-radius: 6px;
  margin: 5px;
  padding: 15px;
}

.modal {
  display: block;
  position: fixed;
  z-index: 111;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.print-preview {
  width: fit-content;
  margin: auto;
  display: flex;
}

.fileDisplay {
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  padding: 5px;
  color: #546271;
  display: flex;
}
.fileDisplay-name {
  margin: 5px;
}
/* .dynamicForm-sign-container {
  margin: 20px 0 0 0;
} */
.dynamicForm-sign-sub-container {
  display: flex;
  gap: 20px;
  margin: 20px 0 0 0;
}

.FileUploader-div {
  font-size: 12px;
  margin: 20px;
  font-weight: 400;
  font-family: "Roboto";
}

.duVAcl {
  min-width: 295px;
}

.main-dynamic-form {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  margin: 5px;
  padding: 15px;
}

.comment-table {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  padding: 15px;
  margin: 5px;
}

.Tab-nav-contract {
  border: 0px solid #eee;
  height: 40px;
  margin-left: 10px;
  display: flex;
  margin: 5px;
  border-radius: 5px;
  cursor: context-menu;
  background: #eef1ff;
}

@media only screen and (min-width: 769px) {
  .text-form {
    height: 30px;
    border: 1px solid #d9dff2;
    border-radius: 5px;
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0px 0px 0px 10px;
    font-size: 13px;
  }
}

.MVvHK {
  height: 29px !important;
  border-top: none !important;
  border-left: none !important;
}

@media only screen and (max-width: 820px) {
  .Tab-nav-contract {
    background: #fff;
    border: 2px solid #eee;
    height: 25px;
    width: 750px;
    margin-left: 10px;
    display: flex;
    margin: 10px;
    border-radius: 5px;
    cursor: context-menu;
    line-height: 1;
    background: #eef1ff;
  }

  .Dynamic-Form {
    border: 0px solid #e9ebec;
    border-radius: 6px;
    margin: 5px;
    width: 750px;
    padding: 15px;
  }

  .sig_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-radius: 8px;
    border: 3px solid #e9ebec;
    border-radius: 6px;
    margin: 10px;
    width: 750px;
    padding: 15px 15px 15px 30px;
    grid-column-gap: 25px;
  }
}

.statusCheck {
  float: left;
  margin: 6px 0 0 10px;
  font-size: 15px;
}

.file-div {
  width: 300px;
  margin: 10px 0px 0px 0px;
  font-family: "Roboto";
}

.stepper-container {
  width: 100%;
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  position: relative;
  text-align: center;
  font-weight: 600;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border: 2px solid #ddd;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.progressbar li:after {
  content: ’’;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: #27ae60;
}

.progressbar li.active:before {
  border-color: #27ae60;
}

.progressbar li.active + li:after {
  background-color: #27ae60;
}

.editor-main-div {
  display: flex;
  overflow-y: auto;
}

@media only screen and (min-width: 820px) {
}

.file-upload-label {
  font-size: 12px;
  color: #546271;
}
.pdf-preview-close-btn {
  margin: 46px 0 0 0;
}
