ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}
.approveButton-reject {
  background: #e25858;
  font-size: 12px;
  height: 35px;
  width: 100px;
  margin-right: 2%;
  color: white;
  border-radius: 4px;
  font-weight: 400;
  float: right;
  padding: 3px;
  font-family: 'Roboto';
}
.approveButton-reject:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}
.approveButton {
  background: #82B1CD;
  font-size: 12px;
  height: 35px;
  width: 100px;
  margin-right: 2%;
  color: white;
  border-radius: 4px;
  font-weight: 400;
  float: right;
  padding: 3px;
  font-family: 'Roboto';
}
.approveButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.approve-editor {
  width: 100%;
}

.MuiBox-root-12 {
  padding: 0px !important;
}
.version-label {
  color: #546271;
  font-size: 14px !important;
  font-weight: 400;
  white-space: nowrap;
  font-family: 'Roboto';
  margin: 10px 0px 10px 25px;
}
.version-div {
  display: flex;
  margin: 15px 0px 0px 0px;
}
.comment-approval-label {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  padding: 15px;
  margin: 20px;
}
.MuiBox-root-8 {
  padding: 0px !important;
}
.MuiBox-root-10 {
  padding: 0px !important;
}
.tab-nav-request {
  background: #eee;
  height: 40px;
  display: flex;
  margin: 40px 0px 0px 20px;
  border-radius: 5px;
  border: 2px solid #eee;
  width:97% ;
}
.css-1jqq78o-placeholder{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
}
.approval-submit{
  text-align: center;
  margin: 10px;
}
