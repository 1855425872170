.Button-div {
  margin: auto;
}
.clauseModal {
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 30% !important;
  max-width: 60% !important;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  min-height: 500px;
  max-height: 600px;
}
.clauseButton{
  margin: 10px;
   padding-Bottom: 5px;
}
.clauseClose{
  float: right;
}
.clauseAdd{
  float: right;
  margin-right: 5%;
}
.Template-clause-label{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto';
}
.label-clauseModal{
  margin: 5px;
}
.clause-div{
  margin: 40px 0 0 0;
}
#clauseEditor{
  margin: 0 0 10px 0;

}