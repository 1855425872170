.reviewButton {
  background: #48336e;
  height: 35px;
  width: 100px;
  margin-right: 20%;
  border: 0px solid #48336e;
  color: white;
  border-radius: 4px;
  float: right;
  padding: 3px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}
.reviewButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}
.commentbar {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  padding: 15px;
  margin: 20px;
}
.review-header {
  margin: 0 auto;
  font-size: 16px;
  font-weight: 800;
  font-family: 'Roboto';
}
.Table-comment {
  border: 3px solid #e9ebec;
  border-radius: 6px;
  margin: 15px;
  padding: 15px;
}
.review-editor {
  margin: 15px 0 0 0;
}
