.Container-User {
  margin: 0 1% 0 1%;
}

.addButtonContainer-User {
  text-align: end;
}

.modalContainer-User {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 60% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 999;
}

.headerModal-User {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  color: white;
  background-color: #0f3460;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 9pt;
  font-weight: 800;
}

.headerTitle-User {
  font-size: 12pt;
  margin: 0;
  font-weight: 500;
}

.divDisplay-User {
  display: grid;
  width: 93%;
  padding: 5px 3%;
}

.labelClass-User {
  color: #546271;
  padding: 5px;
  font-size: 9pt;
  font-weight: 600;
  width: 90%;
}

.textboxInput-User {
  width: 95% !important;
}

.textInput-User:hover {
  box-shadow: 0 0 3px grey;
}

.statusStockCheckBoxes-User {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  flex-wrap: wrap;
}

.divDisplayCheckbox-User {
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  min-height: 30px;
  margin: 0 0 0 1%;
}

.modalCheckbox-User {
  margin: 3px 25px 0 15px;
}

.footerModal-User {
  color: white;
  padding: 5px 0 10px 0;
  display: flex;
  justify-content: center;
}

.User-dropdown-input {
  width: 96.5%;
  height: 36px;
}

.User-dropdown-input-table {
  height: 26px;
  width: 20%;
}

.modalFlexDiv-User {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px;
  min-height: 600px;
  overflow-x: hidden;
}
.user-add-update-btn {
  text-align: right;
  margin: 5px;
}

.buyerTable {
  font-size: 9pt;
}

.User-buyers-table {
  border: 2px solid #eee;
  border-spacing: 5px;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0 0 0;
  width: 20%;
}

.statusStockCheckBoxes-User {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  flex-wrap: wrap;
}

.modalCheckbox-User {
  margin: 3px 25px 0 15px;
}

.labelClass-User {
  color: #546271;
  padding: 5px;
  font-size: 9pt;
  font-weight: 600;
  width: 90%;
}

.Modal-Subheading {
  text-align: center;
  color: #0f3460;
  font-weight: 600;
  margin: 0.25em 0 0 0;
  font-family: "Roboto";
}

.productModal-User {
  z-index: 111 !important;
  display: block !important;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.InputTooltip {
  position: relative;
  display: inline-block;
}
.InputTooltip .InputTooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #0f3460ab;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.InputTooltip:hover .InputTooltiptext {
  visibility: visible;
}

.InputTooltip .InputTooltiptext {
  width: 300px;
  bottom: 125%;
  left: 20%;
  margin-left: -60px;
  padding: 0.25em;
}

.InputTooltip .InputTooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #0f3460ab transparent transparent transparent;
}

.visibleButton {
  border: none;
  background: none;
  position: absolute;
  right: 5%;
  top: 25%;
}
.checkbox-label-user {
  font-size: 9pt;
  width: 80px;
  height: 25px;
  color: #0f3460;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-indent: 5px;
}
.checkbox-user {
  margin: 3px 25px 0 0;
}
.option-user-label,
.user-syfed {
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}
.user-select-dropdown {
  padding: 4px;
  height: 30px;
}
.valid svg {
  margin-top: 5px;
}
.invalid svg {
  margin-top: 5px;
}