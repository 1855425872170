.icon {
  width: 20px !important;
  height: 20px !important;
  margin: auto;
}

.laJDDe {
  width: 23.76px !important;
  height: 25px !important;
}

.css-j204z7-MuiFormControlLabel-root {
  margin: auto !important;
}

.MuiFormControlLabel-label {
  color: black !important;
  font-size: 12px !important;
}

.honeicon {
  content: url("../../assets/icons/Hone1.png");
  padding: 1px;
}

.htwoicon {
  content: url("../../assets/icons/htwo2.png");
  padding: 1px;
}

.hthreeicon {
  content: url("../../assets/icons/hthree3.png");
  padding: 1px;
}

.hfouricon {
  content: url("../../assets/icons/hfour4.png");
  padding: 1px;
}

.hfiveicon {
  content: url("../../assets/icons/hfive5.png");
  padding: 1px;
}

.hsixicon {
  content: url("../../assets/icons/hsix6.png");
  padding: 1px;
}

.boldicon {
  content: url("../../assets/icons/bold.png");
  padding: 1px;
}

.qouteicon {
  content: url("../../assets/icons/qoute.png");
  padding: 1px;
  margin-left: 1px;
  margin-top: 6px;
}

.indentdecreaseicon {
  content: url("../../assets/icons/indentdecrease.png");
  padding: 1px;
}

.indentincreaseicon {
  content: url("../../assets/icons/indentincrease.png");
  padding: 1px;
}

.bulleticon {
  content: url("../../assets/icons/bullet.png");
  padding: 1px;
}

.numberbulleticon {
  content: url("../../assets/icons/numberbullet.png");
  padding: 1px;
}

.italicsicon {
  content: url("../../assets/icons/italics.png");
  padding: 1px;
}

.underlineicon {
  content: url("../../assets/icons/underline.png");
  padding: 1px;
}

.strikeouticon {
  content: url("../../assets/icons/strikethrough.png");
  padding: 1px;
}

.codeicon {
  content: url("../../assets/icons/code.png");
  padding: 1px;
}

.superscripticon {
  content: url("../../assets/icons/superscript.png");
  padding: 1px;
}

.subscripticon {
  content: url("../../assets/icons/subscript.png");
  padding: 1px;
}

.fontcoloricon {
  content: url("../../assets/icons/fontcolor.png");
  padding: 1px;
}

.highlighticon {
  content: url("../../assets/icons/highlight.png");
  padding: 1px;
}

.alignjustifyicon {
  content: url("../../assets/icons/alignjustify.png");
  padding: 1px;
}

.leftalignicon {
  content: url("../../assets/icons/leftalign.png");
  padding: 1px;
}

.centeralignicon {
  content: url("../../assets/icons/centeralign.png");
  padding: 1px;
}

.rightalignicon {
  content: url("../../assets/icons/rightalign.png");
  padding: 1px;
}

.linkicon {
  content: url("../../assets/icons/link.png");
  padding: 1px;
  margin-left: 1px;
  margin-top: 6px;
}

.imageicon {
  content: url("../../assets/icons/image.png");
  padding: 1px;
  height: 24px !important;
  width: 25px !important;
  margin-left: 0px;
  margin-top: 6px;
}

.vedioicon {
  content: url("../../assets/icons/video.png");
  padding: 1px;
  height: 24px !important;
  width: 40px !important;
  margin-left: 6px;
  margin-top: 5px;
  margin-right: 0px;
}

.lineheighticon {
  content: url("../../assets/icons/lineheight.png");
  padding: 1px;
}

.bordertopicon {
  content: url("../../assets/icons/deleterow.png");
  padding: 1px;
}

.borderdownicon {
  content: url("../../assets/icons/addrow.png");
  padding: 1px;
}

.borderrighticon {
  content: url("../../assets/icons/deletecolumn.png");
  padding: 1px;
}

.borderlefticon {
  content: url("../../assets/icons/addcolumn.png");
  padding: 1px;
}

.nobordericon {
  content: url("../../assets/icons/noborders.png");
  padding: 1px;
}

.allbordericon {
  content: url("../../assets/icons/allborders.png");
  padding: 1px;
}

.conditionicon {
  content: url("../../assets/icons/condition.png");
  padding: 1px;
  margin: 5px 1px 1px 1px;
}

.commentsicon {
  content: url("../../assets/icons/comments.png");
  padding: 1px;
  margin: 5px 1px 1px 1px;
}

.variableicon {
  content: url("../../assets/icons/variable.png");
  padding: 1px;
  margin: 5px 1px 1px 1px;
}

.clauseicon {
  content: url("../../assets/icons/clause.png");
  padding: 1px;
  margin: 5px 1px 1px 1px;
}

.margin-tool {
  display: flex;
  margin: 5px 0px 0px 0px;
  /* border-bottom: 1px solid #ffffff; */
}

.toolBarGroup {
  display: flex;
  margin: 5px 0px 0px 0px;
  /* border-bottom: 1px solid #ffffff; */
}

.toolBarGroup-basic-buttons {
  display: flex;
  margin: 10px 0px 0px 10px;
  /* border-bottom: 1px solid #ffffff; */
}

.MuiSwitch-root {
  width: 45px !important;
}

.cQJFee {
  margin-left: -0.25rem !important;
  justify-content: center;
}

.editor-icon-background {
  background-color: transparent;
}
.toolbar-select{
  background: transparent;
  height: 25px;
  border: 0px;
  border-bottom: 2px solid #e8e1f6;
  border-radius: 5px;
}
.font-size-input{
  background: transparent;
  height: 25px;
  border: 0px;
  border-bottom: 2px solid #e8e1f6;
  border-radius: 5px;
  width:35px
}
.icon-width{
  width: 35px;
}
.icon-width-toggle{
  width: 120px;
  margin: 5px 0 0 0;
}
.ul.react-autocomplete-input{
  margin-left: 20%;
}