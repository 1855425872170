* {
  padding: 0;
  margin: 0;
}

.side-nav-bar-icon {
  color: #ffffff;
  font-size: 20px;
  margin: 0px 0px 0px 4px;
}

.close-btn {
  font-size: 12px;
  height: 35px;
  min-width: 80px;
  border: 2px solid #82b1cd;
  color: black !important;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
}

.App-btn {
  font-size: 12px;
  background: #48336e;
  height: 45px;
  width: 129px;
  border: 0px solid #48336e;
  color: white !important;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0.25px;
}

.App-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.next-select-trigger {
  width: 100% !important;
  outline: 0;
  transition: all 100ms linear;
}

.Modal-btn {
  font-size: 12px;
  background: #82b1cd;
  height: 35px;
  min-width: 80px;
  color: black;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
  margin-left: 8%;
}

.Modal-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.Modal-btn:disabled {
  background-color: #82b1cd;
  color: white;
  box-shadow: none;
  opacity: 0.1;
}

.next-input.next-large {
  height: 40px;
  border-radius: 7px;
}

.next-select .next-select-inner:hover {
  border: 2px solid #48336e !important;
}

.next-select .next-select-inner {
  display: inline-flex;
  align-items: center;
  outline: 0;
  color: #333333;
  border: 1px solid #d9dff2 !important;
  border-radius: 5px !important;
  padding: 10px !important;
  height: 38px !important;
  /* margin: 5px 0 0 0 !important; */
}

.next-select-trigger-search {
  height: 16px !important;
}

.next-input.next-focus {
  box-shadow: none;
  border-color: none;
}

.next-input.next-large:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.next-input.next-focus {
  border-color: none !important;
  box-shadow: none !important;
}

.add-contracts-btn {
  font-size: 12px;
  background: #82b1cd;
  height: 35px;
  width: 100px;
  color: black;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
  float: right;
  margin-top: 100px;
  margin-right: 24px;
  white-space: nowrap;
}

.add-contracts-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.approval-status-div {
  display: flex;
}

.label-style {
  color: #546271;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px !important;
  margin-top: 10px;
  margin-right: auto;
  white-space: nowrap;
}

.dynamic-card {
  height: 85vh;
}

.modal-open-btn {
  float: right;
  margin-right: 33px;
  margin-top: 20px;
  font-size: 12px;
  background: #c5e5e3;
  height: 35px;
  width: 100px;
  border: 0px solid #c5e5e3;
  color: black;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
}

.modal-open-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.step-style {
  color: #546271;
  font-family: "Roboto";
  font-size: 14px !important;
  font-weight: 500;

  position: relative;
  left: 30px;
}

.ActiveLabel {
  margin-top: 16px;
  font-size: 10px !important;
  font-weight: 500;
  color: #546271;
}

.next-select-single.next-no-search {
  width: 95% !important;
}

.add_col {
  font-size: 12px;
  background: #48336e;
  height: 35px;
  width: 100px;
  border: 0px solid #48336e;
  color: white !important;
  border-radius: 4px;
  padding: 6px;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 0.5;
  margin: 10px;
}

.add_col:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.next-select-trigger {
  width: 100% !important;
  outline: 0;
  transition: all 100ms linear;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.table-scroll {
  border: 3px solid #eef1ff;
  margin: 5px;
  border-radius: 10px;
}

.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: transparent;
}

.body-wrapper {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  flex: 1 1 auto;
  border-radius: 5px !important;
  box-shadow: 3px 5px 25px 0 rgb(0 0 0 / 10%);
  margin: 5px;
  min-height: 86.5vh;
  max-height: 86.5vh;
  background: #fff;
  overflow-y: auto;
}

.modal {
  z-index: 111 !important;
  display: block !important;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 25% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.header-modal {
  padding: 7px 10px;
  color: #fff;
  background-color: #655d8a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
}

.footer-modal {
  color: white;
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: center;
}

.label-class {
  color: #546271;
  margin: 4px 0px 0px 0px;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}

.textbox-input_1 {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 89.5%;
  font-family: "Roboto";
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.textbox-input_1:focus {
  border: 2px solid #48336e;
}

.div-display {
  display: grid;
  width: 100%;
  padding: 5px 10px 0 10px;
}

.commentLabel {
  color: #546271;
  font-size: 14px !important;
  font-weight: 500;
  margin-right: auto;
  white-space: nowrap;
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px;
  font-family: "Roboto";
}

.TextAreaStyle {
  border-radius: 5px;
  border: 1px solid #e9ebec;
  width: 99%;
  padding: 10px;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: #333;
}

.TextAreaStyle:hover {
  border: 2px solid #e9ebec;
  outline: none;
}

@media only screen and (max-width: 900px) and (max-height: 1440px) {
  .TextAreaStyle:hover {
    border: 2px solid #e9ebec;
  }
}

.Active-checkbox {
  transform: scale(0.6);
  margin-bottom: 12px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #48336e;
  border-radius: 5px;
}

.formheader85 {
  width: 85%;
}

.formheader20 {
  display: flex;
  /* margin: 15px; */
}

.editorButton {
  background-color: #48336e !important;
  font-size: 10px;
  height: 25px;
  width: 100px;
  border: 0px solid #48336e;
  color: white !important;
  margin: 15px !important;
  border-radius: 4px;
  float: right;
}

.editorButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.editorButton:disabled,
editorButton[disabled] {
  cursor: not-allowed;
  background-color: #ccc;
}

.form-columns {
  margin: 2px;
  width: 40%;
}

.formrow {
  display: flex;
}

.setting-label {
  color: #546271;
  font-size: 14px !important;
  font-weight: 500;
  margin-top: 33px;
  margin-right: auto;
  white-space: nowrap;
  padding: 0 0 0 10px;
  font-family: "Roboto";
  line-height: 4.5;
}

.next-overlay-wrapper .next-overlay-inner {
  z-index: 9999 !important;
}

.container-grid {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  row-gap: 10px;
}

.formHeader {
  margin: 5px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  display: flex;
  width: 100%;
  height: 40px;
}

.formCard {
  padding: 20px !important;
  overflow: hidden;
}

.formCard-contract {
  padding: 0px !important;
}

.header-div {
  font-size: 16px;
  font-family: "Roboto";
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
  border: none;
  width: 98%;
  text-align: center;
  font-weight: 800;
  color: #546271;
}

.header-div:hover {
  border-radius: 5px;
  border: 0.5px solid !important;
}

.aster-risk-Icon {
  position: relative;
  bottom: 5px;
  color: red;
  font-size: 5px;
}

.MuiPaper-root-MuiAlert-root {
  color: #fff !important;
  background-color: green !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.MuiAlert-root {
  color: #fff !important;
  background-color: rgb(46, 125, 50) !important;
}

.css-14ewny {
  color: #fff !important;
  background-color: rgb(46, 125, 50) !important;
}

.Table-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}

.tss-tlx3x1-MUIDataTableToolbar-root {
  background: #eef1ff;
}

.MuiBox-root-27 {
  border-bottom: 3px solid #e9ebec;
}

.MuiBox-root-2 {
  border-bottom: 3px solid #eeeeee !important;
}

.MuiBox-root-15 {
  border-bottom: 1px solid #eeeeee !important;
}

.PrivateTabIndicator {
  background: transparent !important;
}

.status-text {
  text-align: center;
  width: inherit;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px;
  font-family: "Roboto";
  color: #48464c;
}
.status-text:hover {
  cursor: pointer;
}

.MuiTabs-root {
  width: fit-content;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  display: flex;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background: #312646;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
}

.Tabs-background {
  background: #7a5bb1 !important;
  margin-right: 0px !important;
}

.tabs-active {
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  width: 50%;
  color: #48336e;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.5;
  font-family: "ROBOTO";
  border-bottom: 4px solid #48336e;
}

.Edit-btn {
  font-size: 12px;
  background: #48336e;
  height: 35px;
  width: 100px;
  border: 0px solid #48336e;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  float: right;
  margin: 20px 10px 0 0;
  line-height: 0.4;
}

.Edit-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.tab-div {
  width: 50%;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  cursor: pointer;
  line-height: 1.5;
  color: #48336e;
}

.Tab-nav {
  background: #fff;
  border: 2px solid #eee;
  height: 40px;
  display: flex;
  width: auto;
  margin: 40px 10px 10px 20px;
  border-radius: 5px;
  background: #eee;
}

.formbutton {
  margin: 10px;
  float: right;
  display: flex;
}

@media only screen and (min-width: 769px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 25% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .css-11mde6h-MuiPaper-root {
    width: 689px !important;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 35% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 50%;
    position: absolute !important;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 820px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .status-main-contract {
    display: block !important;
    margin: 5px;
  }

  .add-contracts-btn {
    font-size: 12px;
    background: #48336e;
    height: 25px;
    width: 100px;
    border: 0px solid #48336e;
    color: white !important;
    border-radius: 4px;
    font-weight: 400;
    float: right;
    margin-top: 100px;
    margin-right: 53px;
    white-space: nowrap;
  }

  .css-11mde6h-MuiPaper-root {
    width: 735px !important;
  }

  .table-scroll {
    border: 3px solid #eef1ff;
    margin: 5px 40px 5px 5px;
    margin-right: 40px;
    border-radius: 10px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 35% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

.page-body-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  width: 96.5%;
  transition: width 0.25s ease, margin 0.25s ease;
}
.page-body-wrapper-open {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  width: 87%;
  transition: width 0.25s ease, margin 0.25s ease;
}
.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: 21vh;
  background: #e7eff0;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  background: #888888;
  width: 100%;
  background-color: #eeeeee;
  color: #000;
  flex-grow: 1;
}

.header-title {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  font-family: "Roboto";
}

.MUIDataTableHeadCell-data {
  font-size: 10px !important;
}

.MuiTablePagination-displayedRows {
  font-size: 10px !important;
}

.MuiTypography-root {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.MuiToolbar-root {
  min-height: 20px !important;
}

.MuiSvgIcon-root {
  font-size: 15px !important;
}

.MuiTableCell-root {
  font-size: 12px !important;
  text-align: left !important;
  padding: 0 10px !important;
  color: #1d1b20 !important;
}

.GLhKi {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  margin: 0 auto !important;
  border-bottom: 0 !important;
}

.next-tree-select-not-found {
  display: none;
}

.contractName {
  width: 100%;
  display: flex;
  margin: 5px 0 0 0px;
}

.MuiButton-root {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10px 0 !important;
  margin: 3px 0 0 0 !important;
  justify-content: flex-start !important;
}

.add-contract-status {
  width: 250px;
  display: block;
  max-width: 235px;
  height: 40px;
  padding: 7px;
  color: #ffffff;
  border: 2px solid #eeeeee;
  white-space: nowrap;
  border-radius: 20px;
  font-weight: 400;
  font-family: "Roboto";
  font-size: 12px;
  border: 2px solid 12, 123, 167;
  text-align: center;
  line-height: 2.5;
}

.MuiPopover-paper {
  position: absolute;
  max-width: calc(100% - 32px);
  min-width: 16px;
}

.byEUOu {
  font-size: 12px !important;
}

.contracts-btn {
  font-size: 12px;
  background: #82b1cd;
  height: 35px;
  width: 100px;
  min-width: 110px;
  color: black;
  border-radius: 4px;
  font-weight: 400;
  margin: 0px 20px 0px 0px;
  white-space: nowrap;
  font-family: "Roboto";
}

.next-input.next-large input {
  font-size: 13px !important;
}

.contracts-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.contracts-btn:disabled,
contracts-btn[disabled] {
  background-color: #82b1cd;
  color: black;
  box-shadow: none;
  opacity: 0.1;
}

.editor-header-label {
  margin: 5px;
  font-size: 16px;
  font-weight: 800;
  font-family: "Roboto";
  color: #546271;
}

.MuiSelect-select {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.MuiMenuItem-root {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.next-input-text-field {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.next-input.next-large input {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.tableHeader {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Roboto" !important;
}

.toaststyle {
  text-wrap: pretty;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}

.css-1dimb5e-singleValue {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}

.MUIDataTableHeadCell-fixedHeader {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
  color: #48464c !important;
}

.removeParent {
  height: 25px !important;
  width: 25px !important;
  margin: 10px 15px 10px 10px;
  font-size: 12px;
  background: #c5e5e3;
  height: 45px;
  width: 129px;
  border: 0px solid #48336e;
  color: black;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
}
.removeParent:hover {
  box-shadow: 0 5px 5px rgb(40 36 51);
}

.parentSelectDiv {
  display: flex;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.jbgwWt {
  height: 35px !important;
}

.menuicon {
  color: rgb(255, 255, 255);
  font-size: 30px;
  margin: 0px;
  width: 20px;
  height: 20px;
}

.steps-container {
  display: flex;
  padding: 20px;
  width: 80%;
}

.step {
  text-align: left;
  flex: 1;
  cursor: default;
  position: relative;
}

.step .line {
  height: 0px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(0% + 50px);
  width: calc(100% - 45px);
}

.step .circle {
  padding: 16px 20px;
  display: inline-block;
  border-radius: 50%;
  margin: 0px 0px 6px 0px;
}

.step-active .circle {
  background: #49316b;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 12px;
  font-family: "Roboto";
  margin: 0px 0px 6px 6px;
  height: 10px;
  width: 5px;
  border-radius: 50%;
  line-height: 1;
}

.step-done .circle {
  color: #0d1087;
  font-size: 12px;

  background: #e8e1f4;
  font-family: "Roboto";
  margin: 0px 0px 6px 6px;
  height: 10px;
  width: 5px;
  border-radius: 50%;
  line-height: 1;
}

.step-inactive .circle {
  background: #bbb;
  color: #000;
}

.step-active .label {
  color: #48464c;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
}

.step-done .label {
  color: #b6b9c3;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
}

.step-inactive .label {
  color: #888;
}

.step-done .line {
  border-color: #d9dff2;
}

.step-inactive .line,
.step-active .line {
  border-color: #eee;
}

.fileNameDisplay {
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  color: #546271;
  padding: 5px 0 0 0;
}

.aster-risk-Icon-amend-termination {
  position: relative;
  left: 190px;
  bottom: 18px;
  color: red;
  font-size: 5px;
}

.alert-danger {
  color: red;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}
.error-editor {
  color: red;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 12px;
}

input:-webkit-autofill {
  transition: background-color 1s ease-in 2000s;
}

.link-active {
  background: #ae73a9;
  padding: 5px;
  border-radius: 10px;
}
.table-active-cell {
  width: 50px;
  height: 8px;
  text-align: center;
  margin: 0px;
  float: none;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
  background: #009243;
  padding-top: 10px;
}
.table-inactive-cell {
  width: 50px;
  height: 8px;
  text-align: center;
  margin: 0px;
  float: none;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
  background: #fe2500;
  padding-top: 10px;
}

.table-active-cell-review {
  width: 80px;
  height: 8px;
  text-align: center;
  margin: 0px;
  float: none;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
  background: #009243;
  padding-top: 10px;
}
.table-inactive-cell-review {
  width: 80px;
  height: 8px;
  text-align: center;
  margin: 0px;
  float: none;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
  background: #fe2500;
  padding-top: 10px;
}

.table-contract-request-status {
  min-width: 80px;
  width: max-content;
  height: 10px;
  text-align: center;
  margin: 0px;
  float: none;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 0.2;
  letter-spacing: 0.25px;
  padding-top: 13px;
}
.active-link {
  color: #ffd255;
  margin: 0px 0px 0px 4px;
  font-size: 25px;
}
.next-tree-node-label {
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  font-size: 14px !important;
}
.view-icon {
  background: none;
}

/* Session Timeout modal */

.session-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  backdrop-filter: blur(5px); /* apply blur filter */
  align-items: center;
  justify-content: center;
  z-index: 111 !important;
  display: block !important;
  padding-top: 100px;
  overflow: auto;
}
.session-p {
  margin: 5px 0 0 0;
  text-align: center;
}

.session-modal-content {
  border-radius: 4px;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 25% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.session-modal h2 {
  margin-top: 0;
}

.sessionModal-btn {
  font-size: 12px;
  background: #82b1cd;
  height: 35px;
  min-width: 80px;
  color: black;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
  margin-left: 8%;
  padding: 5px;
}

.textbox-input_1:hover {
  border: 2px solid #48336e !important;
}
.textbox-input_1:focus {
  outline: none;
  border: 2px solid #48336e !important;
}

.text-form-editor:hover {
  border: 2px solid #48336e !important;
}
.text-form-editor:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.text-form-esignature:hover {
  border: 2px solid #48336e !important;
}
.text-form-esignature:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.Input-box:hover {
  border: 2px solid #48336e !important;
}
.Input-box:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.Textbox-condition:hover {
  border: 2px solid #48336e !important;
}
.Textbox-condition:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.Dynamic-Form-Input:hover {
  border: 2px solid #48336e !important;
}
.Dynamic-Form-Input:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.text-form:hover {
  border: 2px solid #48336e !important;
}
.text-form:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.Textbox-editor:hover {
  border: 2px solid #48336e !important;
}
.Textbox-editor:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.bottom-div:hover {
  border: 2px solid #48336e !important;
}
.bottom-div:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
.comments-inputBox:hover {
  border: 2px solid #48336e !important;
}
.comments-inputBox:focus {
  outline: none;
  border: 2px solid #48336e !important;
}
input[type="checkbox"] {
  accent-color: #48336e;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* .MuiSelect-select:hover {
  border: 2px solid #48336e !important;
}
.MuiSelect-select:focus {
  outline: none;
  border: 2px solid #48336e !important;
} */