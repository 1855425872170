/* Green */

.success {
  border-color: #04aa6d;
  color: green;
}

.success:hover {
  background-color: #04aa6d;
  color: white;
}

/* Blue */
.info {
  border-color: #fcc201;
  color: #fcc201;
}

.info:hover {
  background: #fcc201;
  color: white;
}

/* Orange */
.warning {
  border-color: #ff9800;
  color: orange;
}

.warning:hover {
  background: #ff9800;
  color: white;
}

/* Red */
.danger {
  border-color: #f44336;
  color: red;
}

.danger:hover {
  background: #f44336;
  color: white;
}

.all-arrival-contract {
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  border: 2px solid #ffffff;
  min-width: 50px;
  min-height: 50px;
  margin: 5px 50px;
  padding: 10px;
  font-family: "Roboto";
}

.arrival-contract {
  /* color: #49316b; */
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 2px solid #fff;
  width: 38px;
  height: 38px;
  margin: 0px 59px;
  line-height: 37px;
}
.contract-add-Btn {
  width: 100%;
}

.status-btn-contract {
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding-right: 83px;
  position: relative;
  margin: 10px;
  height: 100px;
  width: 150px;
  border: 2px solid #eef1ff;
}

.all-btn-contract {
  border-radius: 10px;
  border: none;
  color: white;
  padding-right: 83px;
  position: relative;
  margin: 3px;
  height: 100px;
  width: 150px;
  border: 2px solid #eeeeee;
}

.status-btn-contract:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.headerText {
  text-align: center;
}

.approved-contract {
  /* background-color: #8FCAE6; */
  background-color: #eef1ff;
}

.drafted-contract {
  /* background-color: #F3A361; */
  background-color: #eef1ff;
}

.all-contract {
  /* background-color: #39c292;; */
  background-color: #eef1ff;
}

.submitted-contract {
  /* background-color: #3F66B0ff; */
  background-color: #eef1ff;
}

.executed-contract {
  /* background-color: #A66A9A; */
  background-color: #eef1ff;
}

.amended-contract {
  /* background-color: #009E82; */
  background-color: #eef1ff;
}

.terminated-contract {
  /* background-color: #C5857E; */
  background-color: #eef1ff;
}

.Dialog-Condition {
  max-width: 900px;
  height: 400px;
  overflow-y: auto;
}

.status-main-contract {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .status-main-contract {
    display: block !important;
    margin: 5px;
  }
}
.count-div {
  text-align: center;
  width: inherit;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}
.all-contract-label {
  color: #5434bf;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #5434bf;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  line-height: 37px;
}
.all-contract-label:hover {
  cursor: pointer;
}
.drafted-contract-label {
  color: #1d907fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #1d907fff;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.drafted-contract-label:hover {
  cursor: pointer;
}
.submitted-contract-label {
  color: #3f66b0ff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #3f66b0ff;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.submitted-contract-label:hover {
  cursor: pointer;
}
.approve-contract-label {
  color: #4f96d5ff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #4f96d5ff;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.approve-contract-label:hover {
  cursor: pointer;
}
.excuted-contract-label {
  color: #f7901eff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #f7901eff;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.excuted-contract-label:hover {
  cursor: pointer;
}
.amended-contract-label {
  color: #f05623ff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #f05623ff;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.amended-contract-label:hover {
  cursor: pointer;
}
.terminate-contract-label {
  color: #76ccba;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #76ccba;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.terminate-contract-label:hover {
  cursor: pointer;
}
