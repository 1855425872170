.all-request-label {
  color: #5434bf;
  font-size: 12px;
  font-weight: bold;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid #5434bf;
  margin: 8px auto;
  line-height: 37px;
}
.all-request-label:hover {
  cursor: pointer;
}

.approve-request-label {
  color: #4f96d5ff;
  font-size: 12px;
  font-weight: bold;
  display: block;
  width: 38px;
  height: 38;
  border-radius: 50%;
  border: 3px solid #4f96d5ff;
  margin: 8px auto;
  line-height: 37px;
}
.approve-request-label:hover {
  cursor: pointer;
}
.draft-request-label {
  color: #1d907fff;
  font-size: 12px;
  font-weight: bold;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 3px solid #1d907fff;
  margin: 8px auto;
  line-height: 37px;
}
.draft-request-label:hover {
  cursor: pointer;
}
.submit-request-label {
  color: #3f66b0ff;
  font-size: 12px;
  font-weight: bold;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 3px solid #3f66b0ff;
  margin: 8px auto;
  line-height: 37px;
}
.submit-request-label:hover {
  cursor: pointer;
}
.status-btn-request {
  height: 100px;
  width: 150px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding-right: 83px;
  position: relative;
  margin: 10px;
}

.status-btn-request:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.headerText {
  text-align: center;
}

.approved-request {
  background-color: #eef1ff;
}

.drafted-request {
  background-color: #eef1ff;
}

.All-request {
  background-color: #eef1ff;
}

.submitted-request {
  background-color: #eef1ff;
}

@media only screen and (max-width: 768px) {
  .status-main-request {
    display: block !important;
    margin: 15px;
  }
}

.status-main-request {
  display: flex;
}

@media only screen and (max-width: 820px) {
  .status-main-request {
    display: block !important;
    margin: 15px;
  }

  /* .file-uploader {
    display: flex;
    border: 3px solid #e9ebec;
    border-radius: 6px;
    padding: 15px;
    margin: 10px;
    width: 750px;
  } */

  .comment-table {
    border: 3px solid #e9ebec;
    border-radius: 6px;
    padding: 15px;
    margin: 10px;
    width: 750px;
  }

  .css-11mde6h-MuiPaper-root {
    width: 723px !important;
  }
}

.count-div {
  text-align: center;
  width: inherit;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}
.count-div:hover {
  cursor: pointer;
}
.request-add-Btn {
  width: 100%;
}
