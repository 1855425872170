:root {
  --viridian: #1d907fff;
  --true-blue: #3f66b0ff;
  --united-nations-blue: #4f96d5ff;
  --jonquil: #fbcd08ff;
  --yellow-green: #8fc742ff;
  --flame: #f05623ff;
  --plum: #9f4098ff;
  --princeton-orange: #f7901eff;
  --greenblue: #76ccba;
  --darkred: #be5168;
  --bluegreen: #7c9fb0;
  --pinkpurple: #b576ad;
}

.dashboard-main-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.dashboard-label {
  font-family: "Roboto";
  font-size: 16px;
}

.dashboard-contract-status-div {
  background-image: url("../../assets/images/backgroundfordashboardstatusdiv.jpg");
  background-size: cover;
  border: 2px solid #edf2fa;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.dashboard-welcome-back-div {
  background: #edf2fa;
  margin-top: 10px;
  border: 2px solid #edf2fa;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
}

.dashboard-welcome-back-div-label-1 {
  font-size: 50px;

  font-family: cursive;
}

.dashboard-welcome-back-div-label-2 {
  font-family: cursive;
  font-size: 15px;
}

.dashboard-expiry-contracts-main-div {
  background: #edf2fa;

  margin: 10px;
  border-radius: 10px;
  height: fit-content;
  padding: 8px;
  border: 2px solid #edf2fa;
}

.dashboard-expiry-contracts-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 10px;
}

.dashboard-expiry-contracts-sub-div {
  border: 2px solid #bfd3f4;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
}

.dashboard-expiry-contracts-number {
  color: white;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  margin: 10px;
  font-size: 20px;
}

.dashboard-expiry-contracts-label {
  font-weight: 200;
  font-family: "Roboto";
  text-align: center;
  font-size: 20px;
}

.dashboard-acv-contract-div {
  background: #edf2fa;
  border: 2px solid #edf2fa;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
  height: fit-content;
  height: fit-content;
  display: grid;
  width: 100%;
}

.background1 {
  background: var(--viridian);
}

.background2 {
  background: var(--true-blue);
}

.background3 {
  background: var(--united-nations-blue);
}

.background4 {
  background: var(--jonquil);
}

.background5 {
  background: var(--yellow-green);
}

.background6 {
  background: var(--flame);
}

.background7 {
  background: var(--plum);
}

.background8 {
  background: var(--princeton-orange);
}

.background9 {
  background: var(--greenblue);
}

.background10 {
  background: var(--darkred);
}

.background11 {
  background: var(--bluegreen);
}

.background12 {
  background: var(--pinkpurple);
}

.border-1 {
  border: 1px solid var(--viridian);
}

.border-2 {
  border: 1px solid var(--true-blue);
}

.border-3 {
  border: 1px solid var(--united-nations-blue);
}

.border-4 {
  border: 1px solid var(--jonquil);
}

.border-5 {
  border: 1px solid var(--yellow-green);
}

.border-6 {
  border: 1px solid var(--flame);
}

.border-7 {
  border: 1px solid var(--plum);
}

.border-8 {
  border: 1px solid var(--princeton-orange);
}

.border-9 {
  border: 1px solid var(--greenblue);
}

.border-10 {
  border: 1px solid var(--darkred);
}

.border-11 {
  border: 1px solid var(--bluegreen);
}

.border-12 {
  border: 1px solid var(--pinkpurple);
}

.dashboard-status-div {
  height: 30px;
  width: auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  background: white;
  cursor: pointer;
}

.dashboard-request-status-div {
  background: #edf2fa;
  margin: 10px;
  border-radius: 10px;
  height: fit-content;
  padding: 8px;
  border: 2px solid #edf2fa;
  margin-top: 0px;
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 10px;
}

.dashboard-request-status-sub-div {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.dashboard-request-status-inner-div {
  height: fit-content;
  padding: 10px;
  display: grid;
  justify-items: center;
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
}

.dashboard-request-status-inner-div-all {
  background: #1d907f3b;
  border-color: #1d907fff;
}

.dashboard-request-status-inner-div-drafted {
  background: #3f66b04a;
  border-color: #3f66b0ff;
}

.dashboard-request-status-inner-div-submitted {
  background: #4f96d55e;
  border-color: #4f96d5ff;
}

.dashboard-request-status-inner-div-approved {
  background: #8fc74257;
  border-color: #8fc742ff;
}

.dashboard-acv-expiring-renewing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.dashboard-acv-expiring-renewing-div {
  height: 30px;
  width: 92%;
  border-radius: 25px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
  padding: 10px 10px 10px 10px;
  margin: 5px 0px;
}

.dashboard-acv-expiring {
  background: #edf2fa;
  border-radius: 10px;
  padding: 10px;
  margin-top: 0px;
}

.dashboard-acv-request-chart {
  padding: 10px;
}

.dashboard-label-acv-contract {
  text-align: center;
}
