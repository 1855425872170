@media only screen and (max-width: 821px) {
  .icon-background {
    background: #48336e;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
    float: right;
    margin: 5px 75px 0 0;
  }

  .navbar {
    background: #393e46;
    height: 85px;
    display: flex;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-index: 12;
    left: 0px !important;
  }

  .nav-header {
    height: 40px;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 9999;
    display: grid;
    grid-template-columns: 2fr 5fr;
  }
  .nav-toggle {
    width: 17%;
    display: flex;
    /* margin: 0 0 0 45px;
    position: relative;
    right: 150px; */
  }

  .header-menu {
    display: flex;
    margin-left: 830px;
  }

  .item-menu {
    float: right;
    display: flex;
    position: relative;
    bottom: 74%;
  }

  .logoutBtn {
    color: rgb(255, 255, 255);
    font-size: 13px;
  }

  .logout-btn-div {
    margin: 5px 75px 0 0;
    width: 20%;
  }

  .applogo {
    height: 40px;
    margin: 0 0 20px 10px;
  }

  .userName {
    color: #48336e;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
    max-width: 100%;
    margin-right: 12px;
    float: right;
  }

  .nav-header1 {
    display: grid;
    grid-template-columns: 9fr 1fr;
  }

  .userName-div {
    margin: 10px 0px;
  }
  .nav-toggle {
    width: 17%;
    display: flex;
    /* margin: 0 0 0 45px;
    position: relative;
    right: 145px; */
  }
}

@media only screen and (min-width: 820px) {
  .icon-background {
    background: #48336e;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
    float: right;
    margin: 5px 75px 0 0;
  }

  .navbar {
    background: #393e46;
    height: 85px;
    display: flex;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-index: 12;
    left: 0px !important;
  }

  .nav-header {
    height: 40px;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 9999;
    display: grid;
    grid-template-columns: 2fr 5fr;
  }

  .header-menu {
    display: flex;
    margin-left: 830px;
  }

  .item-menu {
    float: right;
    display: flex;
    position: relative;
    bottom: 74%;
  }

  .logoutBtn {
    color: rgb(255, 255, 255);
    font-size: 13px;
  }

  .logout-btn-div {
    margin: 5px 75px 0 0;
    width: 20%;
  }

  .applogo {
    height: 40px;
    margin: 0 0 20px 10px;
  }

  .logo-div {
    font-size: 35px;
    color: #000;
    width: 80%;
    height: 40px;
  }

  .userName {
    color: #48336e;
    margin-right: 12px;
    float: right;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
    max-width: 100%;
  }

  .nav-header1 {
    display: grid;
    grid-template-columns: 9fr 1fr;
  }

  .userName-div {
    margin: 10px 0px;
  }
  .nav-toggle {
    width: 17%;
    display: flex;
    /* margin: 0 0 0 45px;
    position: relative;
    right: 145px; */
  }
}

@media only screen and (min-width: 992px) {
  .icon-background {
    background: #48336e;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
    float: right;
    margin: 5px 75px 0 0;
  }

  .navbar {
    background: #393e46;
    height: 85px;
    display: flex;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-index: 12;
    left: 0px !important;
  }

  .logo-div {
    font-size: 35px;
    color: #000;
    width: 80%;
    height: 40px;
  }

  .nav-header {
    height: 40px;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 9999;
    display: grid;
    grid-template-columns: 2fr 5fr;
  }

  .header-menu {
    display: flex;
    margin-left: 830px;
  }

  .item-menu {
    float: right;
    display: flex;
    position: relative;
    bottom: 74%;
  }

  .logoutBtn {
    color: rgb(255, 255, 255);
    font-size: 13px;
  }

  .logout-btn-div {
    margin: 5px 75px 0 0;
    width: 20%;
  }

  .applogo {
    height: 40px;
    margin: 0 0 20px 10px;
  }

  .logo-div {
    font-size: 35px;
    color: #000;
    width: 80%;
    height: 40px;
  }

  .userName {
    color: #48336e;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
    max-width: 100%;
    margin-right: 12px;
    float: right;
  }

  .nav-header1 {
    display: grid;
    grid-template-columns: 9fr 1fr;
  }

  .userName-div {
    margin: 10px 0px;
  }
  .nav-toggle {
    width: 88%;
    display: flex;
  }
  .userName-div1 {
    width: 85%;
    margin: 10px 0px;
  }
}
