@media only screen and (max-width: 768px) {
  .logo-footer {
    width: 166px;
    height: 51px;
    margin: 0 auto;
    margin-right: 838px;
  }

  .container-logo {
    height: 50px !important;
    margin-left: 30%;
    margin-bottom: 6%;
  }
  .Footer {
    background-color: white;
    width: 100%;
    bottom: 0;
    left: 0 !important;
    min-height: 35px;
    box-shadow: 0 -1px 4px 0 rgb(180 180 180 / 40%);
    /* z-index: 9999; */
  }
  .footer-div {
    text-align: center;
    color: black;
    float: left;
  }
  .footer-text {
    font-weight: 500;
    text-align: center;
    color: #000;
    float: left;
    font-size: 14px;
    font-family: 'Roboto';
    margin: 15px 0 0 60px;
  }
}
@media only screen and (min-width: 769px) {
  .logo-footer {
    width: 166px;
    height: 51px;
    margin: 0 auto;
    margin-right: 838px;
  }

  .container-logo {
    margin-top: 5%;
    height: 40px !important;
    margin-left: 30%;
  }
  .Footer {
    background-color: white;
    width: 100%;
    bottom: 0;
    left: 0 !important;
    min-height: 35px;
    box-shadow: 0 -1px 4px 0 rgb(180 180 180 / 40%);
    /* z-index: 9999; */
  }
  .footer-div {
    text-align: center;
    color: black;
    float: left;
  }
  .footer-text {
    font-weight: 500;
    text-align: center;
    color: #000;
    float: left;
    font-size: 14px;
    font-family: 'Roboto';
    margin: 15px 0 0 60px;
  }
}
