@media only screen and (max-width: 768px) {
  .admin-header {
    width: 100%;
    height: 70px;
    background: #6a5ea6;
    display: none;
    color: white;
    font-size: 40px;
  }

  .HeaderIcon {
    margin: 15px;
  }

  .HeaderName {
    margin-top: 23px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    grid-row-gap: 15px;
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 25px;
    grid-column-gap: 25px;
    column-gap: 25px;
    margin-top: 2%;
  }

  .grid-items {
    border-radius: 15px;
    border: 1px solid #e2e7fb;
    /* width: 250px; */
    min-height: 110px;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: 95% 60%;
    background: #edf2fa;
  }

  .admin-icon-div {
    vertical-align: middle;
    display: table;
    padding: 10px;
    position: relative;
    top: 10%;
    left: 3%;
  }

  .grid-label {
    line-height: 0;
    font-family: Roboto;
    font-size: 1.2em;
    color: #393e46;
    text-align: center;
    white-space: nowrap;
  }

  .class-link:hover {
    text-decoration: none;
  }

  .class-link {
    text-decoration: none;
  }

  .admin-label {
    margin-left: 85px;
    font-size: 42px;
    position: relative;
    bottom: 28px;
    font-weight: 500;
  }

  .MarginDiv {
    padding:20px;
  }

  .admin-sub-menu-div {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 769px) {
  .admin-header {
    width: 100%;
    height: 70px;
    background: #6a5ea6;
    display: none;
    color: white;
    font-size: 40px;
  }

  .HeaderIcon {
    margin: 15px;
  }

  .HeaderName {
    margin-top: 23px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    grid-row-gap: 15px;
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 10px;
  }

  .grid-item {
    height: 100%;
  }

  .grid-items {
    border-radius: 15px;
    border: 1px solid #e2e7fb;
    width: 250px;
    min-height: 110px;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: 95% 60%;
    background: #edf2fa;
  }

  .admin-icon-div {
    vertical-align: middle;
    display: table;
    padding: 10px;
    position: relative;
    top: 10%;
    left: 3%;
  }

  .grid-label {
    line-height: 0;
    font-family: Roboto;
    font-size: 1.2em;
    color: #393e46;
    text-align: center;
    white-space: normal;
  }

  .class-link:hover {
    text-decoration: none;
  }

  .class-link {
    text-decoration: none;
  }

  .admin-label {
    margin-left: 85px;
    font-size: 42px;
    position: relative;
    bottom: 28px;
    font-weight: 500;
  }
}

.MarginDiv {
  padding: 20px;
}

.admin-sub-menu-div {
  margin-top: 10px;
}

.admin-icon-div {
  vertical-align: middle;
  display: table;
  padding: 10px;
  position: relative;
  top: 10%;
  left: 3%;
}

.admin-icon {
  font-size: 27px;
  padding: 12px;
  color: #7a5bb1;
}

.grid-label {
  line-height: 0;
  font-family: Roboto;
  font-size: 1.2em;
  color: #393e46;
  text-align: center;
  white-space: nowrap;
}

.class-link:hover {
  text-decoration: none;
}

.class-link {
  text-decoration: none;
}

.admin-label {
  margin-left: 85px;
  font-size: 42px;
  position: relative;
  bottom: 28px;
  font-weight: 500;
}

.admin-sub-div {
  border-bottom: 3px solid #eeeeee;
  position: relative;
  top: 15%;
}

.icon-groups-card {
  z-index: 9;
  position: relative;
  bottom: 37.5px;
  background: #edf2fa;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #bbbbbb;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.icon-dept-card {
  z-index: 9;
  position: relative;
  bottom: 37.5px;
  background: #edf2fa;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e2e7fb;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  background-position: 25px;
}

.icon-usermanege-card {
  z-index: 9;
  position: relative;
  bottom: 37.5px;
  background: #edf2fa;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #bbbbbb;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.icon-dept-card {
  z-index: 9;
  position: relative;
  bottom: 37.5px;
  background: #edf2fa;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e2e7fb;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.icon-role-card {
  z-index: 9;
  position: relative;
  bottom: 37.5px;
  background: #edf2fa;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #bbbbbb;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.dept-img {
  height: 47px;
  margin-left: 5px;
  margin-top: 5px;
}

.loc-img {
  height: 47px;
  margin-left: 6px;
  margin-top: 10px;
}

.role-img {
  height: 46px;
  margin-left: 8px;
  margin-top: 6px;
}

.user-img {
  height: 50px;

  margin-left: 5px;
}

.admin-text-div {
  height: 40px;
  margin: 25px 0 0 0;
  font-family: "Roboto";
  text-align: center;
  color: #ffffff;
  border-radius: 0 0 12px 12px;
  background: #48336e;
  line-height: 2;
}

.admin-h4 {
  padding: 12px;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 800;
}

.admin-icon-clause {
  font-size: 27px;
  color: #7a5bb1;
  padding: 10px 0px 0px 16px;
}

.admin-icon-contract-Template {
  font-size: 27px;
  color: #7a5bb1;
  padding: 10px 0px 0px 16px;
}

.admin-icon-dept {
  font-size: 27px;
  color: #7a5bb1;
  padding: 7px;
}

.admin-label-text {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Roboto";
  padding: 0px 0 20px 0px;
  color: #393e46;
}
:root {
  --viridian: #1D907Fff;
  --true-blue: #3F66B0ff;
  --united-nations-blue: #4F96D5ff;
  --jonquil: #FBCD08ff;
  --yellow-green: #8FC742ff;
  --flame: #F05623ff;
  --plum: #9F4098ff;
  --princeton-orange: #F7901Eff;
  --greenblue: #76ccba;
  --darkred : #be5168;
  --bluegreen : #7c9fb0;
  --pinkpurple : #b576ad;
}

.color-1{
  color: var(--viridian);
}
.color-2{
  color: var(--true-blue);
}
.color-3{
  color: var(--united-nations-blue);
}
.color-4{
  color: var(--jonquil);
}
.color-5{
  color: var(--yellow-green);
}
.color-6{
  color: var(--flame);
}
.color-7{
  color: var(--plum);
}
.color-8{
  color: var(--princeton-orange);
}
.color-9{
  color: var(--greenblue);
}
.color-10{
  color: var(--darkred);
}
.color-11{
  color: var(--bluegreen);
}
.color-12{
  color: var(--pinkpurple);
}
.color-13{
  color:#D5794Fff;
}
.color-14{
  color:#9FD54Fff;
}
.background-1{
  background: var(--viridian);
}
.background-2{
  background: var(--true-blue);
}
.background-3{
  background: var(--united-nations-blue);
}
.background-4{
  background: var(--jonquil);
}
.background-5{
  background: var(--yellow-green);
}
.background-6{
  background: var(--flame);
}
.background-7{
  background: var(--plum);
}
.background-8{
  background: var(--princeton-orange);
}
.background-9{
  background: var(--greenblue);
}
.background-10{
  background: var(--darkred);
}
.background-11{
  background: var(--bluegreen);
}
.background-12{
  background: var(--pinkpurple);
}
.background-13 {
  background: #D5794Fff;
}
.background-14 {
  background: #9FD54Fff;
}
@media only screen and (max-width: 820px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    grid-row-gap: 15px;
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 10px;
  }
}
