.comment-thread-popover {
  display: none;
  background: white;
  position: absolute;
  margin-top: 4px;
  min-width: 350px;
  z-index: 1;
}

.condition-list {
  min-height: 80px;
  max-height: 50vh;
  overflow-y: auto;
}

.comment-row {
  display: flex;
  margin: 4px 16px;
}

.comment-author-name {
  display: block;
  font-weight: bold;
  margin-top: 4px;
}

.comment-text {
  max-width: 350px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
}

.comment-author-photo {
  font-size: 24px;
  margin-right: 4px;
}

.comment-creation-time {
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
}

.comment-input-wrapper {
  border-top: 1px solid #ced4da;
  display: flex;
  padding: 4px;
}

.comment-input {
  border: 0;
}

.comment-input:focus {
  box-shadow: none;
}
