.react-search-field-demo {
  padding: 0;
}

.react-search-field-demo:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

.custom-ul {
  list-style: none;
  margin: 0;
  font-size: 13px;
  width: 100%;
  display: inline-flex;
  padding: 0px;
  border-bottom: 1px solid #ddd;
}

.custom-li {
  list-style: none;
  margin: 0;
  font-size: 13px;
  width: 100%;
  padding: 20px;
  cursor: pointer;
}

.ul-without-padding {
  display: inline-flex;
  border-bottom: 1px solid #ddd;
  list-style: none;
  margin: 0;
  font-size: 13px;
  width: 100%;
}

.list-example {
  position: fixed;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border: 1px solid #ddd;
  width: auto;
  display: table;
  overflow-y: auto;
  transition: 0.2s;
}

.list-example-hidden {
  display: none;
}

.list-body {
  display: flex;
  flex-direction: column;
}

.pointer > :hover {
  cursor: pointer;
}
