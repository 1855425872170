.headerHolder {
  display: flex;
}
#terminationVarTab{
  width: 20%;
  border-radius: 5px !important;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 10px 10px 20px !important;
  border: 2px solid #eeeeee;
}
#terminationEditor{
  margin: 0 0 10px 0;
}
.Tab-nav-editor {
  background: #fff;
  height: 40px;
  display: flex;
  width: 300px;
  margin: 10px 0 10px 5px;
  border-radius: 5px;
  background: #eef1ff;
}
