.Input-box {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  height: 30px;
  margin: 20px 20px 20px 0;
}

.Input-box:focus {
  border: 2px solid #48336e;
}

.btn-submit {
  margin-left: 15px;
  font-size: 11px;
  background: #48336e;
  height: 25px;
  width: 100px;
  border: 0px solid #48336e;
  color: white !important;
  border-radius: 3px;
  padding: 6px;
  font-weight: 500;
}

.arrClose {
  width: 25px;
  height: 25px;
  margin: 5px 15px 0px 10px;
}

.colArr {
  display: flex;
}

.master-div {
  padding: 10px;
  margin-top: 2%;
  text-align: center;
}

.add-row {
  font-size: 12px;
  background: #48336e;
  height: 25px;
  width: 100px;
  border: 0px solid #48336e;
  color: white;
  border-radius: 4px;
  padding: 6px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 0.5;
  margin: auto;
}
.add-row:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.modal-content-master {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 25% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.master-datatable {
  border: 3px solid #eeeeee;
  margin: 5px;
  border-radius: 10px;
}
