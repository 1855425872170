.condition-thread-popover-header {
  display: flex;
  min-height: 31px;
}
.prevnextCondition {
  color: #48336e;
}
.deleteCondition {
  background: #48336e;
  border: 2px solid #48336e;
  color: #fff;
  border-radius: 5px;
  height: 33px;
  min-width: 70px;
  margin-left: auto;
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
}
.deleteCondition:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06) !important;
  color: rgb(170, 170, 170) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.conditionLabel {
  margin-top: auto;
  margin-bottom: auto;
}
.condition-footer{
  padding: 0.75rem 1.25rem;
  background-color: rgba(0,0,0,.03);
  border-top: 1px solid rgba(0,0,0,.125);
}
.condition-body{
    flex: 1 1 auto;
    padding: 1.25rem;
}
.condition-header{
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.condition-card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;

}

