.signature-block-styling {
  height: 70px;
  display: inline-table;
  width: 200px;
  margin: 15px;
}

hr {
  border: 2px solid black;
}

ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

.sig-identifier-container {
  color: white;
  margin: 3px 3px 0px 3px;
}

.sig-heading-container {
  margin: 0px 3px 0px 3px;
}

.editor-negotiation {
  position: relative;
  /* display: inline-flex; */
  width: 65%;
  margin: auto;
  /* margin: 10px; */
}

.NegoButton {
  margin: 0 10px 10px !important;
  font-size: 12px;
  background: #48336e !important;
  height: 35px;
  width: 150px;
  border: 0 solid #48336e !important;
  color: #fff !important;
  border-radius: 4px;
  font-weight: 500;
  margin: 0 20px 0 0;
  white-space: nowrap;
  font-family: "Roboto";
}

.NegoButton:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.nego-Body {
  /* position: relative;
  width: calc(99% - 5px);
  max-width: 99%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  flex: 1 1 auto;
  border-radius: 5px !important;
  box-shadow: 3px 5px 25px 0 rgb(0 0 0 / 10%);
  margin: 10px 10px 10px;
  /* max-height: 85.5vh; */
  /* min-height: 120.5vh;
  max-height: 85.5vh;
  background: #fff;
  overflow-x: hidden; */

  position: relative;
  width: calc(99% - 5px);
  max-width: 99%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  flex: 1 1 auto;
  border-radius: 5px !important;
  box-shadow: 3px 5px 25px 0 rgb(0 0 0 / 10%);
  margin: 10px 10px 10px;
  max-height: 87.5vh;
  /* max-height: 120.5vh; */
  min-height: 85vh;
  background: #fff;
  overflow-x: dden;
  overflow-y: auto;
}

.page-body-wrapper-nego {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  transition: width 0.25s ease, margin 0.25s ease;
}

.nego-editor-div {
  display: flex;
  overflow-y: auto;
}

.nego-header-div {
  font-size: 18px;
  color: rgb(0, 0, 0);
  width: 100%;
  padding-left: 20px;
  background: white;
  font-family: "Roboto";
  display: flex;
}

#negotiationEditor {
  border: 1px solid white;
  max-width: 100%;
  width: 100%;
  margin: 10px;
}

#negotiationDiffEditor {
  border: 1px solid white;
  max-width: 100%;
  width: 100%;
  margin: 10px;
}

#singlenegotiationEditor {
  border: 1px solid white;
  max-width: 100%;
  width: 90%;
  margin: auto;
}

.nego-logo {
  height: 35px;
  margin: 5px;
}
.nego-header {
  margin: 10px;
}
.nego-expired {
  font-size: 24px;
  font-weight: 800;
  font-family: "Roboto";
  margin-top: 35px;
}
