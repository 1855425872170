
.success {
  border-color: #04aa6d;
  color: green;
}

.success:hover {
  background-color: #04aa6d;
  color: white;
}

.info {
  border-color: #fcc201;
  color: #fcc201;
}

.info:hover {
  background: #fcc201;
  color: white;
}

.warning {
  border-color: #ff9800;
  color: orange;
}

.warning:hover {
  background: #ff9800;
  color: white;
}

.danger {
  border-color: #f44336;
  color: red;
}

.danger:hover {
  background: #f44336;
  color: white;
}

.all-approve-label {
  color: #39c292;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #39c292;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}
.pending-approve-label {
  color: #F3A361;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  display: block;
  border: 3px solid #F3A361;
  width: 38px;
  height: 38px;
  margin: auto;
  line-height: 37px;
}

.status-btn {
  height: 100px;
  width:150px;
  border-radius: 10px;
  border: none;
  color: white;
  padding-right: 83px;
  position: relative;
  margin: 10px;
}

.status-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.pending-status {
  height: 100px;
    width: 150px;
    border-radius: 10px;
    color: white;
    padding-right: 83px;
    padding-left: 23px;
    position: relative;
    margin: 8px;
    white-space: nowrap;
}

.pending-status:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.approved {
  background-color: #06a999;
}

.drafted {
   background-color:#eef1ff;
}

.Declined {
  background-color: #e36945;
}

.submitted {
  background-color:#eef1ff;
}

.status-pending {
  width: 65px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
}

.status-pending-margin {
  margin-left: 25px;
}
.count-div{
  text-align: center;
  width: inherit;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}
.Tab-nav-approval{
  background: #fff;
  border: 2px solid #eef1ff;
  height: 40px;
  display: flex;
  width: 300px;
  margin: 0px 10px 0px 10px;
  border-radius: 5px;
  background: #eef1ff;
}