/* add css styles here (optional) */
.Dynamic-Form-Headings {
  margin-top: 20px;
  padding: 20px;
  border: 3px solid #eeee;
  border-radius: 6px;
}

.FormWrapper {
  transition: 0.3s;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Dynamic-form-Label {
  float: left;
  width: 80%;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto";
  white-space: nowrap;
  color: black;
  padding: 5px;
  margin: 0px;
}

.Dynamic-Form {
  border-radius: 6px;
  margin: 5px;
}

.Dynamic-Form-Element {
  float: left;
  width: 80%;
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
}

.Dynamic-Form-elements {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-radius: 5px;
  margin: 20px 0 0 0;
}

.Dynamic-Form-elements-column {
  margin-left: 10px;
}

.Dynamic-Form-Input {
  height: 30px;
  border: 2px solid #d9dff2;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
  padding: 0px 0px 0px 10px;
}

.Dynamic-Form-Input:focus-visible {
  border: 3px solid #d9dff2 !important;
}

.Dynamic-Form-Input:hover {
  border: 3px solid #d9dff2;
}

.Dynamic-Form-Input:focus {
  border: 2px solid #d9dff2;
}

.Dynamic-Form-Input:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

.Dynamic-Form-TextArea {
  height: 23px;
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 100%;
  resize: vertical !important;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0 0 10px;
  font-family: "Roboto";
}
.Dynamic-Form-TextArea:focus {
  height: 50px;
  border: 1px solid #d9dff2;
  border-radius: 5px;
  width: 100%;
  resize: vertical !important;
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0 0 10px;
  font-family: "Roboto";
}

.Dynamic-Form-TextArea:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

#standard-basic:disabled {
  cursor: not-allowed;
  border: 2px solid;
  background-color: rgba(60, 60, 60, 0.06);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.3);
}

.contract-header {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-family: "Roboto";
  line-height: 16px;
}

@media only screen and (max-width: 768px) {
  .Dynamic-Form-elements {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-radius: 5px;
    margin: 20px 0 0 0;
  }
}

@media only screen and (min-width: 769px) {
  .Dynamic-Form-elements {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 5px;
  }
}

.hUIPtu {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
  padding: 0px 0px 0px 10px !important;
}

.ribbon-header {
  position: relative;
  padding: 5px;
  font-size: 2em;
  line-height: 1.875em;
  color: #e6e2c8;
  border-radius: 0 0.156em 0.156em 0;
  background: #f4eeff;
  width: 15%;
  line-height: 0.5;
}

.signature-ribbon-header {
  position: relative;
  padding: 5px;
  font-size: 2em;
  line-height: 1.875em;
  color: #e6e2c8;
  border-radius: 0 0.156em 0.156em 0;
  background: #f4eeff;
  width: 37%;
  line-height: 0.5;
}
.Termination-Signatory-ribbon-header {
  position: relative;
  padding: 5px;
  font-size: 2em;
  line-height: 1.875em;
  color: #e6e2c8;
  border-radius: 0 0.156em 0.156em 0;
  background: #f4eeff;
  line-height: 0.5;
  width: 55%;
}

.signature-ribbon-header:before,
.signature-ribbon-header:after {
  position: absolute;
  content: "";
  display: block;
}
.Termination-Signatory-ribbon-header:before,
.Termination-Signatory-ribbon-header:after {
  position: absolute;
  content: "";
  display: block;
}

.ribbon-header:before,
.ribbon-header:after {
  position: absolute;
  content: "";
  display: block;
}

.signature-ribbon-header:before {
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
  box-sizing: unset !important;
}
.Termination-Signatory-ribbon-header:before {
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
  box-sizing: unset !important;
}
.Termination-Signatory-ribbon-header:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  box-sizing: unset !important;
}

.ribbon-header:before {
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
  box-sizing: unset !important;
}

.signature-ribbon-header:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  box-sizing: unset !important;
}
.signature-ribbon-header:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  box-sizing: unset !important;
}

.ribbon-header:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  box-sizing: unset !important;
}

@media (max-width: 600px) {
  body {
    font-size: 0.875em;
  }

  .ribbon-header {
    line-height: 1.143em;
    padding: 0.5em;
  }

  .ribbon-header:before,
  .ribbon-header:after {
    font-size: 0.714em;
  }
}
