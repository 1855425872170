.signature-label {
  color: #546271;
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Roboto';
  width: 300%;
  padding: 0 0 20px 0;
  line-height: 2;
}

.settingTab {
  width: 100%;
}

.sign-btn {
  width: 122px;
  height: 32px;
  background: #48336e;
  color: #fff !important;
  font-size: 13px;
  font-family: Roboto;
  margin: auto !important;
}

.sign-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
  background: #48336e;
}

.sign-btn:disabled,
sign-btn[disabled] {
  background-color: #48336e;
  color: #fff;
  box-shadow: none;
  opacity: 0.1;
}

.text-form-esignature {
  border: 1px solid #D9DFF2;
  border-radius: 5px;
  width: 100%;
  /* margin: 10px 0 0 0; */
  font-size: 12px;
  padding: 10px;
}

.text-form-esignature:focus {
  border: 2px solid #48336e;
}

.sign-label {
  float: left;
  width: 80%;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto";
  white-space: nowrap;
  color: #546271;
  padding: 5px;
  margin: 0px;
}

.docusignImg {
  width: 75vw;
}

/* .stepImage {
  margin: 1px 2px 2px 100px;
  width: auto !important;
} */
.signature-formrow {
  display: flex;
}

.signature-form-columns {
  margin: 2px;
  width: 20%;
  white-space: nowrap;
}

.signature-header {
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  color: #546271;
}

.sign-icon {
  position: relative;
  left: 35px;
  top: 10px;
}

.signature-form-columns {
  width: 100%;
}

.adobeImg {
  width: 40vw;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.signature-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px;
  column-gap: 20px;
  row-gap: 20px;
}

.signature-grid-items {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 100px;
  background: ghostwhite;
  border-radius: 5px;
  padding: 5px;
}

.sign-link {
  text-decoration: none;
}

.Docusign-logo {
  width: 150px;
}

.sign-img {
  text-align: center;
  padding: 5px;
}

.saleforces-logo {
  width: 110px;
}

.adobeSign-logo {
  width: 220px;
}

.sign-tooltip {
  position: relative;
  display: inline-block;
}

.sign-tooltip .sign-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.sign-tooltip:hover .sign-tooltiptext {
  visibility: visible;
}

.salesforce-main {
  margin: 60px auto;
}

.freshworks-logo {
  width: 120px;
}

.pandoc-logo {
  width: 90px;
}

.emudhra-logo {
  width: 160px;
  padding: 20px;
}
.freshsalesSuites-logo{
  width: 170px;
  padding: 30px;
}
.workday-logo{
  width: 150px;
  padding: 5px;
}
.freshteam-logo{
  width: 150px;
  padding: 10px;
}
.signature-container-grid{
  display: grid;
  padding: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.signature-grid-column{
  margin: 2px;
  width: 15%;
}
