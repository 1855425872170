.clause-header {
  display: flex;
}

.editor-clause-edit {
  background: #48336e;
  height: 35px;
  width: 100px;
  border: 0px solid #48336e5f;
  color: white;
  border-radius: 3px;
  padding: 6px;
  font-family: "Roboto";
  font-weight: 400;
  margin: 15px;
  font-size: 12px;
  float: right;
  line-height: 1;
}
.editor-clause-edit:hover {
  box-shadow: 0 5px 5px rgb(40 36 51);
}

@media only screen and (max-width: 768px) {
  .MuiDialog-paperWidthSm {
    width: 63%;
  }
}

@media only screen and (min-width: 769px) {
  .MuiDialog-paperWidthSm {
    width: 30%;
  }
}

.DraftEditor-root {
  margin-left: 1.9cm !important;
  margin-right: 1.32cm !important;
}

.editor-class {
  border: 2px solid rgb(145, 140, 140);
  width: 210mm;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.wrapper-class {
  width: 100%;
  height: 70vh;
  background: #f3f3f3;
  /* overflow: auto; */
  margin-left: auto;
  margin-right: auto;
}

.textarea {
  min-height: 50px;
  width: 300mm;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 21.5%;
}

.buttondiv {
  margin-left: auto;
  margin-right: auto;
}

.MuiTab-root {
  min-width: 105px !important;
}

.rdw-editor-main {
  /* overflow: unset !important; */
  min-height: 100% !important;
  height: auto !important;
  width: 98%;
}

.ClauseformHeader {
  width: 80%;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  padding: 20px;
  font-family: "Roboto";
}

.SubmitEditor {
  margin-left: auto;
  margin-right: 30px;
  font-size: 14px;
  background: #48336e;
  height: 36px;
  width: 129px;
  border: 0px solid #48336e;
  color: white !important;
  border-radius: 3px;
  padding: 6px;
  font-weight: 500;
  float: right;
}

.SubmitEditor:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.Active-label {
  color: #546271;
  padding: 7px 5px 5px 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
}
#clauseVarTab {
  width: 20%;
  border-radius: 5px !important;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 10px 10px 20px !important;
  border: 2px solid #eeeeee;
}
