.item-menu {
  margin-left: 10%;
  display: flex;
  position: relative;
  bottom: 74%;
}

.approvals {
  content: url(../../assets/icons/approvals.png);
}

.reviewer {
  content: url(../../assets/icons/review.png);
}

.reports {
  content: url(../../assets/icons/reports.png);
}

.Admin {
  content: url(../../assets/icons/admin.png);
}

.dashboard {
  content: url(../../assets/icons/dashboard.png);
}

.Contracts {
  content: url(../../assets/icons/contract.png);
}

.Requests {
  content: url(../../assets/icons/request.png);
}

.logoutBtn {
  color: rgb(255, 255, 255);
  font-size: 15px;
  margin-left: 1px;
}

.sidebar {
  height: 100vh;
  width: 15%;
  background: #49316b;
}

.sidebar1 {
  height: 100vh;
  width: 3.5%;
  background: #49316b;
}

.toggle-menu {
  margin: 13px;
}

.toggle-menu-icon {
  height: 20px;
  width: 20px;
  color: #fff;
  font-size: 25px;
  margin: 0 0 0 1px;
}

.sidebar-ul {
  list-style: none;
}

.sidebar-li {
  text-align: start;
  margin: 30px 10px;
  height: 25px;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  white-space: nowrap;
}

.sidebar-h2 {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  margin: 5px 0 0 10px;
  color: #fff;
}

.menu-tooltip {
  position: relative;
}

.menu-tooltip .menu-tooltip-span {
  visibility: hidden;
  width: 80px;
  background-color: #eee;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px 5px;
  position: absolute;
  z-index: 3;
  top: 2px;
  left: 50px;
  white-space: nowrap;
  font-size: 10px;
  font-family: "Roboto";
  color: #000;
}

.menu-tooltip:hover .menu-tooltip-span {
  visibility: visible;
}

.menu-tooltip .menu-tooltip-span-opensidebar {
  visibility: hidden;
  width: 77px;
  background-color: #eee;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 190px;
  white-space: nowrap;
  font-size: 10px;
  font-family: "Roboto";
  color: #000;
}

.menu-tooltip:hover .menu-tooltip-span-opensidebar {
  visibility: hidden;
}

.sidebar-breadcrumb {
  text-align: start;
  margin: 0px 0 0 25px;
  padding-top: 4%;
}
.sidebar-breadcrumb-none {
  display: none;
}

@media only screen and (min-width: 820px) {
  .sidebar-breadcrumb {
    text-align: start;
    margin: 0px 0 0 25px;
    padding-top: 4%;
  }
}
@media only screen and (max-width: 820px) {
  .sidebar {
    height: 100vh;
    width: 22%;
    background: #49316b;
  }

  .sidebar1 {
    height: 100vh;
    width: 5.5%;
    background: #49316b;
  }
}

.idebar-ul > a.active {
  background: #ae73a9;
  padding: 5px;
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}
