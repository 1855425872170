.Button-div {
  margin: auto;
}
.Textbox-condition {
  border: 1px solid #d9dff2;
  border-radius: 5px;
  height: 15px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: "Roboto";
  margin: 5px 0px 10px 0;
}
.Textbox-condition:focus {
  border: 2px solid #48336e;
}

.Condtion-Close {
  color: #fff;
  background: #48336e;
  border: 1px solid #48336e;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 10px;
  margin: 5px;
  float: right;
}
.Condtion-Close:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}
.dailog-Size {
  max-width: 900px !important;
}
.div-padding {
  padding: 10px;
}
.modal-condtion {
  position: absolute !important;
  background-color: #fefefe;
  margin: auto;
  padding: 0px;
  border: 1px solid #888;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 40% !important;
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
  border-radius: 5px;
}
.Condition-div {
  display: grid;
  grid-row-gap: 10px;
  border: 2px solid #eeeeee;
  margin: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #d9dff2;
}
.condition-grid-row {
  display: flex;
  justify-content: space-around;
}
.condition-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  font-family: "Roboto";
}
.condition-Main-label {
  color: rgb(84, 98, 113);
  font-weight: 500;
  margin-top: 2%;
  font-size: 14px;
  font-family: "Roboto";
}
.OnArrAdd {
  display: flex;
  justify-content: center;
}
.onConditionAdd {
  font-size: 12px;
  background: #48336e;
  height: 25px;
  min-width: 80px;
  border: 1px solid #48336e;
  color: white;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 400;
}
.ActiveCheckbox {
  margin: 5px;
}
