/* .add-approval-btn {
  font-size: 10px !important;
  background: #48336e !important;
  height: 25px !important;
  width: 30px !important;
  border: 0px solid #48336e !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 6px !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  letter-spacing: 0.25px !important;
}

.add-approval-btn:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06) !important;
  color: rgb(170, 170, 170) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
} */

.DraftEditor-root {
  margin-left: 1.9cm !important;
  margin-right: 1.32cm !important;
}

.editor-class {
  border: 2px solid rgb(145, 140, 140);
  min-height: 297mm;
  width: 210mm;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.wrapper-class {
  width: 300mm;
  height: 65vh;
  background: #f3f3f3;
  /* overflow: auto; */
  margin-left: auto;
  margin-right: auto;
}

.textarea {
  min-height: 50px;
  width: 300mm;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 21.5%;
}

.rdw-editor-main {
  height: unset !important;
}

.variablesdiv {
  border: 2px solid;
  display: grid;
  height: 500px;
  overflow: auto;
}

.variablebutton {
  min-width: 100px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.buttondiv {
  margin-left: auto;
  margin-right: auto;
}

.buttoncheckbox {
  display: grid;
  grid-template-columns: 140px 30px;
  align-items: flex-start;
}

.MuiTab-root {
  min-width: 105px !important;
}

.settingTab {
  width: 100%;
}

.btn-step {
  padding: 4px;
  height: 30px;
  width: 50px;
  background: #c5e5e3;
  border: none;
  border-radius: 6px;
  color: black;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  letter-spacing: 0.25px;
}

.btn-step:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.btn-step:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06) !important;
  color: rgb(170, 170, 170) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.btn-step-same-level {
  padding: 4px;
  height: 25px;
  width: 25px;
  background: #48336e;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}

.btn-step-same-level:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}

.btn-step-same-level:disabled {
  cursor: not-allowed;
  background-color: rgba(60, 60, 60, 0.06) !important;
  color: rgb(170, 170, 170) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.div-step {
  margin-left: 35px;
}

.buttons-operation {
  background: #48336e;
  margin-left: 23px;
  height: 33px;
  width: 45px;
  border: none;
  color: white;
  border-radius: 6px;
  margin-top: 42px;
}

.settings-textbox {
  margin-left: 222px;
  margin-top: -35px;
  width: 206px;
}

.col-label {
  margin-top: 40px;
  margin-left: 43px;
}

.name-textbox {
  margin-left: 232px;
  margin-top: -31px;
  width: 206px;
}

.approver {
  margin-right: 455px;
  margin-top: -248px;
}

.approver-dropdown {
  margin-top: -37px;
  float: right;
  margin-right: -84px;
}

.label-approver {
  margin-top: 2px;
  margin-left: 1224px;
}

.formcol {
  margin-left: 856px;
  margin-top: 28px;
}

.contract-name {
  margin-top: 20px;
  margin-left: 48px;
}

.settings-textbox-1 {
  margin-left: 222px;
  margin-top: -35px;
  width: 206px;
}

.settings-textbox-2 {
  margin-left: 222px;
  margin-top: -35px;
  width: 206px;
}

.config {
  /* margin-top: 55px;
  margin-left: 800px; */
  grid-template-columns: 0px 0px 0px 60px;
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: 50px 1fr;
}

.text-form-editor {
  width: 88%;
  height: 17px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #d9dff2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  margin: 10px 0 0px 0;
}

.text-form-editor:focus {
  border: 2px solid #48336e;
}

.select-approver {
  top: 15px;
}

/* .select-reviewer {
  margin-left: 18px;
} */

.treeselect {
  margin-left: 12%;
  margin-top: 1.5%;
}

.Component-root-12 {
  color: unset;
}

@media only screen and (max-width: 821px) {
  .text-form-editor {
    margin-left: 4%;
  }
}

.signature-block-styling {
  height: 70px;
  display: inline-table;
  width: 200px;
  margin: 15px;
}

.sig-identifier-container {
  color: white;
  margin: 3px 3px 0px 3px;
}

.sig-heading-container {
  margin: 0px 3px 0px 3px;
}

.step-app-Add {
  margin-top: 1%;
  margin-left: 2%;
}

.Approvers-div {
  display: flex;

  align-items: center;
}

.form-border {
  border: 1px solid #e9ebec;
  border-radius: 6px;
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
  margin: 5px;
}

.Tab-nav-editor {
  background: #fff;

  height: 40px;
  display: flex;
  width: 300px;
  margin: 10px 0px 10px 5px;
  border-radius: 5px;
  background: #eef1ff;
}

.headerHolder {
  display: flex;
}

.EditorCheckBox {
  transform: scale(1.5);
  margin: 20px 5px 5px 5px;
  accent-color: #48336e;
}

.editor-TextBox {
  border: 1px solid #48336e;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-family: "Roboto";
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  margin-top: 25px;
}
.editor-contracts-btn {
  font-size: 12px;
  background: #c5e5e3;
  height: 35px;
  width: 100px;
  min-width: 110px;
  border: 0 solid #c5e5e3;
  color: #000;
  border-radius: 4px;
  font-weight: 400;
  margin: 0 5px 0 0;
  white-space: nowrap;
  font-family: "Roboto";
}
.editor-contracts-btn:hover {
  box-shadow: 0 5px 5px rgb(40 36 51 / 50%);
}
.editor-contracts-btn:disabled {
  background-color: #82b1cd;
  color: #000;
  box-shadow: none;
  opacity: 0.1;
}

.material-select-template-settings {
  height: 40px;
  width: 95%;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #e9ebec !important;
}
.editor-step-approval {
  border: 1px solid #f0e9ff;
  width: 150px;
  height: 70px;
  text-align: center;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}
.editor-userIcon {
  font-size: 25px;
  margin-left: 7px;
  margin-bottom: 5px;
  color: #6d739d;
}
.editor-minu-btn {
  margin: auto;
}
.editor-removeIcon {
  background: red;
  bottom: 10px;
  position: relative;
  left: 50px;
  height: 15px;
  width: 17px;
  border-radius: 3px;
  color: white;
  padding-top: 2px;
}

.main-div-settings-div {
  border: 1px solid #c5e5e3;
  border-radius: 5px;
  margin: 10px;
}
.notifications-messages {
  display: flex;
}
.modal-content-notification {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 80% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.notificationSettings-add-div {
  display: grid;
}
.notification-settings {
  display: flex;
}
.EditornotificationCheckBox {
  margin: 24px 5px 5px 5px;
  accent-color: #48336e;
}
.noti-upd-btn {
  margin-left: 3% !important;
}
