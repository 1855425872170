.tippy-box[data-theme~="tomato"] {
  background-color: tomato;
  color: yellow;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

table,
th,
/* td {
  border: 1px solid black;
} */
table {
  border-collapse: collapse;
}

button {
  background-color: white;
  border: none;
  /* opacity: 0.5; */
}

.btnActive {
  opacity: 1;
}

.editor-wrapper {
  font: unset !important;
  line-height: unset !important;
  margin: auto;
  font-family: "Roboto";
  min-height: 400px;
  padding: 0;
  position: relative;
  display: block;
  padding: 10px;
  min-width: 35%;
  padding: 0 10px;
  background: #525659;
  max-width: 100%;
  padding: 0px 10px;
}

.editor-comp-container {
  display: flex;
  position: relative;
}

table {
  width: 100%;
}

td {
  height: 50px;
  padding: 0 5px;
}

.popup-wrapper {
  display: inline;
  position: relative;
}

.popup {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;
}

button {
  cursor: pointer;
}

.sidebar-wrapper {
  background-color: #fff;
  height: 100%;
  min-width: 13.5%;
  font-size: 11px;
}

.sidebar-wrapper>.container {
  margin: 0;
  padding: 0;
}

.editor-container {
  background: #fff;
  border-width: 1 1 1 6;
  flex-grow: 1;
  overflow-y: auto;
  align-items: center;
  height: calc(116vh - 56px);
}

.editor {
  min-height: 84vh;
  position: relative;
  display: inline-flex;
  min-width: 50.5%;
  width: 21cm;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background: white;
}

.variable-Style {
  width: 20%;
  border-radius: 5px !important;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 5px 10px 5px !important;
  border: 1px solid #eeeeee;
}

.parties-style {
  width: 20%;
  border-radius: 5px !important;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px 5px 10px 5px !important;
  border: 1px solid #eeeeee;
}

.cQJFee {
  width: 100% !important;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: content-box;
  color: #48336e !important;
  min-height: 40px !important;
  position: relative;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0rem !important;
  padding: 1px 18px 0px !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  padding: 0px 0px 0px !important;
  border-bottom: 2px solid #eee;
}

.editor-comment {
  display: flex;
  width: 100%;
  height: 88vh;
  overflow: auto;
  background: #525659;
}

.plateTool {
  width: 100%;
  margin: auto;
  background: #FAF4FF;
}

.editorContent {
  /* box-shadow: 3px 5px 25px 0 rgb(0 0 0 / 30%); */
  border: 1px solid white;
  max-width: 80%;
  width: 68%;
  margin: auto;
}

.editor-full {
  display: flex;
}

.eyOSHW {
  background: #ddd;
  padding: 5px;
  border-radius: 7px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  padding: 10px 18px 17px !important;
  border-bottom: 0px !important;
}

.gCxyey {
  font-size: 15px !important;
  margin: 10px !important;
  padding: 1px 18px 0 0 !important;
  border-bottom: 2px solid #eee !important;
}

.Tab-nav-variable {
  height: 40px;
  display: flex;
  border-radius: 5px;
  font-family: "Roboto";
  background: #EEF1FF;
  line-height: 1.5;
  margin: 5px;
}

.tab-div-variable {
  width: 50%;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  font-family: "Roboto"
}

.tabs-active-variable {
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  width: 50%;
  color: white;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Roboto";
  color: #48336e;
  border-bottom: 4px solid #48336e;
  margin: 0 0 0 0px;
}

.fAHveZ {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding: 1px 18px !important;
}

.fJWwed {
  margin: 10px !important;
}

#contractEditor {
  border: 1px solid white;
  max-width: 100%;
  width: 100%;
}

#contractNegoEditor {
  border: 1px solid white;
  max-width: 100%;
  width: 100%;
  zoom: 75%;
}

#contractNegoEditor>div.plateTool {
  height: 13.7vh;
}

#contractNegoEditor>div.plateTool>div {
  display: none;
}

#templateEditor {
  border: 1px solid white;
  max-width: 80%;
  width: 68%;
  margin: 0 0 10px 0;
}

#clauseEditorModalPopup {
  border: 1px solid white;
  max-width: 120%;
  width: 102%;
  margin: 5px;
}